import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/gasBPIDs";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getDistributorList } from "appRedux/actions/admin/distributors";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;

const moduleName = "Gas BPID"
const moduleNameUPPER = "GASBPIDS"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class ManageGasBPIDs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                "id": false,
                "res_sme": false,
                "state": false,
                "distributor": false,
                "retailer": false,
                "eme_id": false,
                "plan": false,
                "bpid_link": false,
                "start_date": false,
                "expiry_date": false,
                "created": false,
            },
            gasDistributorList:[
                'AGN Bairnsdale',
                'AGN Cardinia',
                'AGN Mildura',
                'AGN Murray Valley (VIC)',
                'AGN North',
                'AGN Central 1',
                'AGN Central 2',
                'AusNet Services Adjoining Central',
                'AusNet Services Adjoining West',
                'AusNet Services Central 1',
                'AusNet Services Central 2',
                'AusNet Services West',
                'Multinet Main 1',
                'Multinet Main 2',
                'Multinet Yarra Valley',
                'AGN Adelong, Gundagai and Tumut',
                'AGN Albury',
                'AGN Bombala and Cooma',
                'AGN Murray Valley (NSW)',
                'AGN North (NSW)',
                'AGN Temora, Culcairn, Holbrook, Henty and Walla Walla',
                'AGN Wagga Wagga',
                'Evoenergy Queanbeyan',
                'Evoenergy Shoalhaven',
                'Allgas Energy NSW',
                'Central Ranges Pipeline Tamworth',
                'Jemena Capital Region',
                'Jemena Coastal Network',
                'Jemena Country Network',
                'AusNet Services (NSW)',
                'AGN Brisbane and Riverview',
                'AGN Northern',
                'AGN Wide Bay',
                'Allgas Energy Qld',
                'AGN Adelaide',
                'AGN Mount Gambier',
                'AGN Port Pirie',
                'AGN Riverland/Murray Bridge',
                'AGN Whyalla',
                'ActewAGL',
            ]
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'RES/SME',
                dataIndex: 'res_sme',
                key: 'res_sme',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.state.localeCompare(b.state) },
            },
            {
                title: 'Distributor',
                dataIndex: 'distributor',
                key: 'distributor',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.distributor.localeCompare(b.distributor) },
            },
            {
                title: 'Retailer',
                dataIndex: 'retailer',
                key: 'retailer',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
            },
            {
                title: 'Plan',
                dataIndex: 'plan',
                key: 'plan',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.plan.localeCompare(b.plan) },
            },
            {
                title: 'BPID Link',
                dataIndex: 'bpid_link',
                key: 'bpid_link',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.bpid_link.localeCompare(b.bpid_link) },
                render: (text, record) => {
                    return <a href={text} target={"_blank"}>
                        View BPID
                    </a>
                }
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                "id": true,
                "res_sme": true,
                "state": true,
                "distributor": true,
                "retailer": true,
                "eme_id": true,
                "plan": true,
                "bpid_link": true,
                "start_date": true,
                "expiry_date": true,
                "created": true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                "id": false,
                "res_sme": false,
                "state": false,
                "distributor": false,
                "retailer": false,
                "eme_id": false,
                "plan": false,
                "bpid_link": false,
                "start_date": false,
                "expiry_date": false,
                "created": false,
            }
        })
    };

    duplicateRecord(record) {

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ created: true })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtResSmeSearch;
        delete values.txtRetailerSearch;
        delete values.txtDistributorSearch;
        delete values.txtPlanSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                values.start_date = moment(values.start_date,"DD-MM-YYYY").format("YYYY-MM-DD");
            
                if(values.start_date == "Invalid date")
                    values.start_date = "0000-00-00";
                
                values.expiry_date = moment(values.expiry_date,"DD-MM-YYYY").format("YYYY-MM-DD");

                if(values.expiry_date == "Invalid date")
                    values.expiry_date = "0000-00-00";

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            
            delete values.created;
            console.log(this.state.singleRecordDetails)

            values.id = this.state.singleRecordDetails.id;
            console.log(values)

            values.start_date = moment(values.start_date,"DD-MM-YYYY").format("YYYY-MM-DD");
            
            if(values.start_date == "Invalid date")
                values.start_date = "0000-00-00";
            
            values.expiry_date = moment(values.expiry_date,"DD-MM-YYYY").format("YYYY-MM-DD");

            if(values.expiry_date == "Invalid date")
                values.expiry_date = "0000-00-00";

            values = this.removeSearchValuesFromPayload(values);

            this.setState({
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateSingleRecord(values);
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        //this.props.getDistributorList();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtRetailerSearch != "-1" && { retailer: values.txtRetailerSearch }),
                ...(values.txtDistributorSearch != "-1" && { distributor: values.txtDistributorSearch }),
                ...(values.txtPlanSearch != "-1" && { plan: values.txtPlanSearch }),
                ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
                ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.gasBPIDRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.gasBPIDRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="Victoria">Victoria</Option>
                                                            <Option value="New South Wales">New South Wales</Option>
                                                            <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                            <Option value="Queensland">Queensland</Option>
                                                            <Option value="South Australia">South Australia</Option>
                                                            <Option value="Western Australia">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="RES/SME" >
                                                    {getFieldDecorator('txtResSmeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Distributor" >
                                                    {getFieldDecorator('txtDistributorSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Distributor"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Distributors</Option>
                                                            {!isEmpty(this.state.gasDistributorList) && this.state.gasDistributorList.map((gasDistributor, key) => {
                                                                return <option key={key} value={gasDistributor}>{gasDistributor}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('txtRetailerSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Retailer"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Retailers</Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerFriendlyName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Plan" >
                                                    {getFieldDecorator('txtPlanSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            disabled
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Plan"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Plans</Option>
                                                            {/* {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerFriendlyName}</option>;
                                                            })} */}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            <Row>
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="State" >
                                                            {getFieldDecorator('state', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.state : undefined,
                                                                rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.state}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select State"
                                                                >
                                                                    <Option value="Victoria">Victoria</Option>
                                                                    <Option value="New South Wales">New South Wales</Option>
                                                                    <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                                    <Option value="Queensland">Queensland</Option>
                                                                    <Option value="South Australia">South Australia</Option>
                                                                    <Option value="Western Australia">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="RES/SME" >
                                                            {getFieldDecorator('res_sme', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.res_sme : undefined,
                                                                rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.res_sme}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="RES">RES</Option>
                                                                    <Option key="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Distributor" >
                                                            {getFieldDecorator('distributor', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.distributor : undefined,
                                                                rules: [{ required: true, message: 'Please select a Distributor!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.distributor}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Distributor"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.state.gasDistributorList) && this.state.gasDistributorList.map((gasDistributor, key) => {
                                                                        return <option key={key} value={gasDistributor}>{gasDistributor}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Retailer" >
                                                            {getFieldDecorator('retailer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.retailer : undefined,
                                                                rules: [{ required: true, message: 'Please select a Retailer!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.retailer}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Retailer"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                        return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerFriendlyName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Plan" >
                                                            {getFieldDecorator('plan', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.plan : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.plan} style={{ width: '100%' }} placeholder="Enter Plan" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="BPID Link" >
                                                            {getFieldDecorator('bpid_link', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.bpid_link : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.bpid_link} style={{ width: '100%' }} placeholder="Enter BPID Link" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout} label="Start Date" >
                                                        {getFieldDecorator('start_date', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.start_date,"YYYY-MM-DD") : "",
                                                        rules: [{ required: false, message: 'Please select a Start Date!' }],
                                                        })(
                                                        <DatePicker disabled={this.state.fieldState.start_date} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                        )}
                                                    </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout} label="Expiry Date" >
                                                        {getFieldDecorator('expiry_date', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.expiry_date,"YYYY-MM-DD") : "",
                                                        rules: [{ required: false, message: 'Please select an Expiry Date!' }],
                                                        })(
                                                        <DatePicker disabled={this.state.fieldState.expiry_date} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                        )}
                                                    </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe} style={{ marginLeft: "10px" }}>
                                <Button type="danger">
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,
    //distributorList: state.distributors.distributorList,

    gasBPIDRecords: state.gasBPIDs.resultList,
    recordUpdateStatus: state.gasBPIDs.recordUpdateStatus,
    error: state.gasBPIDs.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),
    getDistributorList: (data) => dispath(getDistributorList(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManageGasBPIDs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);