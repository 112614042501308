import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import PostcodeMap from "./admin/postcodeMap";
import Distributors from "./admin/distributors";
import Retailers from "./admin/retailers";
import Users from "./admin/users";
import AccessLevels from "./admin/accessLevels";
import SalesInformation from "./sales/salesInformation";
import RetailerFileMapping from "./admin/retailerFileMapping";
import DistributorTranslations from "./admin/distributorTranslations";
import Plans from "./admin/plans";
import ElecRates from "./admin/elecRates";
import GasRates from "./admin/gasRates";
import DmoVdo from "./admin/dmovdo";
import Tariff from "./admin/tariff";
import Product from "./admin/products";
import PDF from "./admin/pdfs";
import Consumptions from "./admin/consumptions";
import ElecBPIDs from "./admin/elecBPIDs";
import GasBPIDs from "./admin/gasBPIDs";
import ModuleAccess from "./admin/moduleAccess";
import DNCRegister from "./admin/dncRegister";
import RateTool from "./admin/rateTool";

import ManageStatus from "./admin/manageStatus";
import ManageMailingList from "./admin/manageMailingList";

import DiallerPacks from "./admin/diallerPacks";
import InternalDNC from "./admin/internalDNC";
import LeadData from "./admin/leadData";

import ProductAdviceData from "./script/productAdviceData";

import ProcessSales from "./sales/processSales";
import Leads from "./sales/leads";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  common: Common,
  postcodemap: PostcodeMap,
  distributors: Distributors,
  retailers: Retailers,
  users: Users,
  plans: Plans,
  accesslevels: AccessLevels,
  salesinformation: SalesInformation,
  retailerFileMapping: RetailerFileMapping,
  distributorTranslations: DistributorTranslations,
  diallerPacks: DiallerPacks,
  internalDNC: InternalDNC,
  leadData: LeadData,
  manageStatus: ManageStatus,
  manageMailingList: ManageMailingList,
  productAdviceData: ProductAdviceData,
  elecRates: ElecRates,
  gasRates: GasRates,
  processSales: ProcessSales,
  dmoVdo: DmoVdo,
  tariff: Tariff,
  product: Product,
  pdf: PDF,
  consumptions: Consumptions,
  elecBPIDs: ElecBPIDs,
  gasBPIDs: GasBPIDs,
  moduleAccess: ModuleAccess,
  leads: Leads,
  dncRegister: DNCRegister,
  rateTool:RateTool,
});

export default reducers;
