import React from "react";
import { connect } from "react-redux";

import { Spin, Card, Table, Col, Form, Row, Select, DatePicker, Carousel } from "antd";
import { Bar, BarChart, CartesianGrid, Legend, Line, LabelList, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ContainerHeader from "components/ContainerHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import EcommerceStatus from "components/Metrics/EcommerceStatus";
import AmCharts from "@amcharts/amcharts3-react";

import LineIndicator from "components/dashboard/CRM/LineIndicator";

import Widget from "components/Widget/index";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getSalesDataPerHour } from "appRedux/actions/sales/salesInformation";

import isEmpty from "util/isEmpty";

import { GoFlame } from 'react-icons/go';
import { TiFlash } from 'react-icons/ti';
import { FaTruck } from 'react-icons/fa';
import { GiClockwiseRotation } from 'react-icons/gi';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
var momentTimezone = require('moment-timezone');
const RangePicker = DatePicker.RangePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const columns = [
  {
    title: 'Agent Name',
    dataIndex: 'name',
    render: (text, record) => {
      return <div className="gx-flex-row gx-align-items-center">
        <p style={{ fontSize: 17 }} className="gx-mb-0">{record.name}</p>
      </div>
    },
  },
  {
    title: 'Total Sales',
    dataIndex: 'totalSales',
    align: 'center',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.totalSales - b.totalSales,
    render: (text, record) => {
      return <span style={{ fontSize: 20 }} className="gx-text-grey">{record.totalSales}</span>
    },

  },
  {
    title: 'Total Elec',
    dataIndex: 'totalElec',
    align: 'center',
    render: (text, record) => {
      return <span style={{ fontSize: 20 }} className="gx-text-grey">{record.totalElec}</span>
    },

  },
  {
    title: 'Total Gas',
    dataIndex: 'totalGas',
    align: 'center',
    render: (text, record) => {
      return <span style={{ fontSize: 20 }} className="gx-text-grey">{record.totalGas}</span>
    },

  },

];

class FloorDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      fiveWorkingDaysaleStats: [],
      threeWeekDaySaleStats: [],
      salesPerHourData: [],
      salesSummaryData: {
        totalSales: 0,
        totalElec: 0,
        totalGas: 0,
      },
      startDate: null,
      endDate: null,
      agentData: [],
      lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm A'),
      elecRetailerSummary: [],
      gasRetailerSummary: [],
      elecProductSummary: [],
      gasProductSummary: [],
      moveInTransferSummary: {
        moveIn: 0,
        transfer: 0,
      },
    };


  }

  componentDidMount() {

    this.props.getSalesDataPerHour();

    this.intervalID = setInterval(this.props.getSalesDataPerHour.bind(this), 60000);

  }

  componentWillUnmount() {

    clearInterval(this.intervalID);

  }

  handleDateRangeChange = (dates, dateStrings) => {

    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })

  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate, dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {

        if(now.day()!=0)
        {
            dates.push(now.format('YYYY-MM-DD'));
        }

        //dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }
    return dates;
};

  componentWillReceiveProps(nextProps) {

    if (!isEmpty(nextProps.fiveWorkingDaysaleStats)) {
      this.setState({
        fiveWorkingDaysaleStats: nextProps.fiveWorkingDaysaleStats,
        isFetchingData: false,
        lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm A'),
      });
    }

    if (!isEmpty(nextProps.threeWeekDaySaleStats)) {
      this.setState({
        threeWeekDaySaleStats: nextProps.threeWeekDaySaleStats,
        isFetchingData: false,
        lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm A'),
      });
    }

    if (!isEmpty(nextProps.salesPerHourList)) {
      this.setState({
        salesPerHourData: nextProps.salesPerHourList,
        isFetchingData: false,
        lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm A'),
      });
    }


    if (!isEmpty(nextProps.salesSummaryData)) {
      this.setState({
        salesSummaryData: nextProps.salesSummaryData,
      });
    }

    if (!isEmpty(nextProps.agentSaleSummary)) {
      this.state.agentData = [];
      let count = 1;
      nextProps.agentSaleSummary.map((agentData, key) => {
        let record = {
          key: count.toString(),
          name: agentData.name,
          totalSales: !isEmpty(agentData.scores.total) ? agentData.scores.total : "0",
          totalElec: !isEmpty(agentData.scores.elec) ? agentData.scores.elec : "0",
          totalGas: !isEmpty(agentData.scores.gas) ? agentData.scores.gas : "0",
        };
        this.state.agentData.push(record);
        count++;
      });
    }

    if (!isEmpty(nextProps.elecRetailerStats)) {
      this.setState({
        elecRetailerSummary: nextProps.elecRetailerStats,
      });
    }

    if (!isEmpty(nextProps.gasRetailerStats)) {
      this.setState({
        gasRetailerSummary: nextProps.gasRetailerStats,
      });
    }

    if (!isEmpty(nextProps.moveInTransferStats)) {
      this.setState({
        moveInTransferSummary: nextProps.moveInTransferStats,
      });
    }

    if (!isEmpty(nextProps.elecProductStats)) {
      this.setState({
        elecProductSummary: nextProps.elecProductStats,
      });
    }

    if (!isEmpty(nextProps.gasProductStats)) {
      this.setState({
        gasProductSummary: nextProps.gasProductStats,
      });
    }

    if (!isEmpty(nextProps.fetchStatus)) {
      if (nextProps.fetchStatus == "Fetching") {
        this.setState({
          isFetchingData: true,
        });
      }
    }
    else {
      this.setState({
        isFetchingData: false,
      });
    }
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const fiveDayWorkingDayList = this.enumerateDaysBetweenDates(momentTimezone().add(-5,"days"),momentTimezone());

    return (

      <div className="gx-main-content">
        <ContainerHeader title={<h2 className="h2 gx-mb-0">
          LIVE Sales Dashboard <span className="h6 gx-mb-0">(Last Updated at {this.state.lastUpdatedDateTime} ) </span><Spin style={{ float: "right" }} spinning={this.state.isFetchingData}></Spin></h2>} />
        <Carousel autoplay effect="fade" dotPosition="top" dots={true} autoplaySpeed={6000} pauseOnHover={true}>
        <div>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-col-full">
                <ContainerHeader title={<h4 className="h4 gx-mb-0">SALES SUMMARY</h4>} />
              </Col>
            </Row>
            <Row>
              <Col xl={8} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Widget>
                  <div className="gx-media gx-align-items-center gx-flex-nowrap">
                    <div className="gx-mr-lg-4 gx-mr-3">
                      <TiFlash color={"#1493C3"} size={40} /><GoFlame color={"#E59D2D"} size={40}/>
                    </div>
                    <div className="gx-media-body">
                      <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalSales}</h1>
                      <p className="gx-text-grey gx-mb-0">Total Sales (Elec + Gas)</p>
                    </div>
                  </div>
                </Widget>
                {/* <IconWithTextCard icon="growth" iconColor="success" title={this.state.salesSummaryData.totalSales} subTitle="Total Accounts" /> */}
              </Col>
              <Col xl={8} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Widget>
                  <div className="gx-media gx-align-items-center gx-flex-nowrap">
                    <div className="gx-mr-lg-4 gx-mr-3">
                      <TiFlash color={"#1493C3"} size={40} />
                    </div>
                    <div className="gx-media-body">
                      <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalElec}</h1>
                      <p className="gx-text-grey gx-mb-0">Electricity Sales</p>
                    </div>
                  </div>
                </Widget>
                {/* <IconWithTextCard icon="growth" iconColor="primary" title={this.state.salesSummaryData.totalElec} subTitle="Elec Accounts" /> */}
              </Col>
              <Col xl={8} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Widget>
                  <div className="gx-media gx-align-items-center gx-flex-nowrap">
                    <div className="gx-mr-lg-4 gx-mr-3">
                      <GoFlame color={"#E59D2D"} size={40}/>
                    </div>
                    <div className="gx-media-body">
                      <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalGas}</h1>
                      <p className="gx-text-grey gx-mb-0">Gas Sales</p>
                    </div>
                  </div>
                </Widget>
                {/* <IconWithTextCard icon="growth" iconColor="warning" title={this.state.salesSummaryData.totalGas} subTitle="Gas Accounts" /> */}
              </Col>
            </Row>
            <Row> 
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-col-full">
                <ContainerHeader title={<h4 className="h4 gx-mb-0">MOVE IN / TRANSFER SUMMARY</h4>} />
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Widget>
                  <div className="gx-media gx-align-items-center gx-flex-nowrap">
                    <div className="gx-mr-lg-4 gx-mr-3">
                      <FaTruck color={"#E52D2D"} size={40}/>
                    </div>
                    <div className="gx-media-body">
                      <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.moveInTransferSummary.moveIn}</h1>
                      <p className="gx-text-grey gx-mb-0">Move-In Sales</p>
                    </div>
                  </div>
                </Widget>
                {/* <IconWithTextCard icon="signin" iconColor="success" title={this.state.moveInTransferSummary.moveIn} subTitle="Move In" /> */}
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Widget>
                  <div className="gx-media gx-align-items-center gx-flex-nowrap">
                    <div className="gx-mr-lg-4 gx-mr-3">
                      <GiClockwiseRotation color={"#11CE00"} size={40}/>
                    </div>
                    <div className="gx-media-body">
                      <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.moveInTransferSummary.transfer}</h1>
                      <p className="gx-text-grey gx-mb-0">Transfer Sales</p>
                    </div>
                  </div>
                </Widget>
                {/* <IconWithTextCard icon="reply" iconColor="primary" title={this.state.moveInTransferSummary.transfer} subTitle="Transfer" /> */}
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={12}>
                <Card className="gx-card" title="Hourly Sales Stats Last 5 Working Days (Including today)">
                  <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={this.state.fiveWorkingDaysaleStats}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <XAxis dataKey="section" interval={"preserveStartEnd"} />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey={`${fiveDayWorkingDayList[4]}`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey={`${fiveDayWorkingDayList[3]}`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                      <Line type="monotone" dataKey={`${fiveDayWorkingDayList[2]}`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey={`${fiveDayWorkingDayList[1]}`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey={`${fiveDayWorkingDayList[0]}`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col md={12}>
                <Card className="gx-card" title="Total Sales Stats Last 5 Working Days (Including today)">
                  <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={this.state.fiveWorkingDaysaleStats}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <XAxis dataKey="section" interval={"preserveStartEnd"} />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey={`Day4Total`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey={`Day3Total`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                      <Line type="monotone" dataKey={`Day2Total`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey={`Day1Total`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey={`Day0Total`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col md={12}>
                <Card className="gx-card" title="Historical Sales per Hour Trail for Today">
                  <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={this.state.threeWeekDaySaleStats}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <XAxis dataKey="section" interval={"preserveStartEnd"} />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="thisWeek" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey="lastWeek" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                      <Line type="monotone" dataKey="weekBeforeLast" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSales" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col md={12}>
                <Card className="gx-card" title="Historical Total Sales Trail for Today">
                  <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={this.state.threeWeekDaySaleStats}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <XAxis dataKey="section" interval={"preserveStartEnd"} />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="thisWeekCummulative" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey="lastWeekCummulative" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                      <Line type="monotone" dataKey="weekBeforeLastCummulative" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                      <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSalesCummulative" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          </div>
          <div>
            <Card className="gx-card" title="Retailer Summary (Electricity)">
              <Row>
                <Col xl={24} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart data={this.state.elecRetailerSummary}
                      margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="1 1" />
                      <Tooltip cursor={{ fill: 'transparent' }} />
                      <Legend />
                      <Bar name={'Electricity'} dataKey="elec" fill="#003366">
                        <LabelList name={'Electricity'} datakey="elec" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <Card className="gx-card" title="Retailer Summary (Gas)">
              <Row>
                <Col xl={24} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart data={this.state.gasRetailerSummary}
                      margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="1 1" />
                      <Tooltip cursor={{ fill: 'transparent' }} />
                      <Legend />
                      <Bar name={'Gas'} dataKey="gas" fill="#FE9E15">
                        <LabelList  name={'Gas'} datakey="gas" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <Card className="gx-card" title="Product Summary (Electricity)">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <ul className="gx-line-indicator">
                      {!isEmpty(this.state.elecProductSummary) && this.state.elecProductSummary.map((elecProduct, key) => {
                        if (key < (Object.keys(this.state.elecProductSummary).length / 2))
                          return <li><LineIndicator width={((elecProduct.sales / this.state.salesSummaryData.totalElec) * 100).toFixed(2)} title={`${elecProduct.name} (${elecProduct.retailer})`} color="cyan" value={<span>{((elecProduct.sales / this.state.salesSummaryData.totalElec) * 100).toFixed(1)}%</span>} /></li>;
                      })}
                    </ul>
                  </ResponsiveContainer>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <ul className="gx-line-indicator">
                      {!isEmpty(this.state.elecProductSummary) && this.state.elecProductSummary.map((elecProduct, key) => {
                        if (key > ((Object.keys(this.state.elecProductSummary).length / 2) - 1))
                          return <li><LineIndicator width={((elecProduct.sales / this.state.salesSummaryData.totalElec) * 100).toFixed(2)} title={`${elecProduct.name} (${elecProduct.retailer})`} color="cyan" value={<span>{((elecProduct.sales / this.state.salesSummaryData.totalElec) * 100).toFixed(1)}%</span>} /></li>;
                      })}
                    </ul>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <Card className="gx-card" title="Product Summary (Gas)">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <ul className="gx-line-indicator">
                      {!isEmpty(this.state.gasProductSummary) && this.state.gasProductSummary.map((gasProduct, key) => {
                        if (key < (Object.keys(this.state.gasProductSummary).length / 2))
                          return <li><LineIndicator width={((gasProduct.sales / this.state.salesSummaryData.totalGas) * 100).toFixed(2)} title={`${gasProduct.name} (${gasProduct.retailer})`} color="orange" value={<span>{((gasProduct.sales / this.state.salesSummaryData.totalGas) * 100).toFixed(1)}%</span>} /></li>;
                      })}
                    </ul>
                  </ResponsiveContainer>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <ResponsiveContainer width="100%" height="100%">
                    <ul className="gx-line-indicator">
                      {!isEmpty(this.state.gasProductSummary) && this.state.gasProductSummary.map((gasProduct, key) => {
                        if (key > ((Object.keys(this.state.gasProductSummary).length / 2) - 1))
                          return <li><LineIndicator width={((gasProduct.sales / this.state.salesSummaryData.totalGas) * 100).toFixed(2)} title={`${gasProduct.name} (${gasProduct.retailer})`} color="orange" value={<span>{((gasProduct.sales / this.state.salesSummaryData.totalGas) * 100).toFixed(1)}%</span>} /></li>;
                      })}
                    </ul>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <Card className="gx-card" title="Sales Per Hour Information (sales = elec + gas)">
              <ResponsiveContainer width="100%" height={500}>
                <LineChart data={this.state.salesPerHourData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <XAxis dataKey="section" interval={"preserveStartEnd"} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" name={'Total Fuels'} dataKey="sales" stroke="#66000c" activeDot={{ r: 12 }} />
                  <Line type="monotone" name={'Total Electricity'} dataKey="elec" stroke="#3293a8" activeDot={{ r: 6 }} />
                  <Line type="monotone" name={'Total Gas'} dataKey="gas" stroke="#3257a8" activeDot={{ r: 6 }} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </div>
          <div>
            <Widget
              title={
                <h2 className="h4 gx-text-capitalize gx-mb-0">
                  Agent Summary</h2>
              } >
              <div className="gx-table-responsive">
                <Table
                  className="gx-table-no-bordered"
                  columns={columns}
                  dataSource={this.state.agentData}
                  pagination={{ pageSize: 8, defaultCurrent: 1 }}
                  size="small" />
              </div>
            </Widget>
          </div>
          <div>
            <Widget
              title={
                <h2 className="h4 gx-text-capitalize gx-mb-0">
                  Agent Summary</h2>
              } >
              <div className="gx-table-responsive">
                <Table
                  className="gx-table-no-bordered"
                  columns={columns}
                  dataSource={this.state.agentData}
                  pagination={{ pageSize: 8, defaultCurrent: 2 }}
                  size="small" />
              </div>
            </Widget>
          </div>
          <div>
            <Widget
              title={
                <h2 className="h4 gx-text-capitalize gx-mb-0">
                  Agent Summary</h2>
              } >
              <div className="gx-table-responsive">
                <Table
                  className="gx-table-no-bordered"
                  columns={columns}
                  dataSource={this.state.agentData}
                  pagination={{ pageSize: 8, defaultCurrent: 3 }}
                  size="small" />
              </div>
            </Widget>
          </div>
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fiveWorkingDaysaleStats: state.salesinformation.fiveWorkingDaysaleStats,
  threeWeekDaySaleStats: state.salesinformation.threeWeekDaySaleStats,
  salesPerHourList: state.salesinformation.salesPerHourList,
  salesSummaryData: state.salesinformation.salesSummaryData,
  agentSaleSummary: state.salesinformation.agentSaleSummary,
  elecRetailerStats: state.salesinformation.elecRetailerStats,
  gasRetailerStats: state.salesinformation.gasRetailerStats,
  moveInTransferStats: state.salesinformation.moveInTransferStats,
  elecProductStats: state.salesinformation.elecProductStats,
  gasProductStats: state.salesinformation.gasProductStats,

  fetchStatus: state.salesinformation.fetchStatus,
});

const mapDispatchToProps = dispath => ({
  getSalesDataPerHour: (data) => dispath(getSalesDataPerHour(data)),
});

const WrappedAdvancedSearch = Form.create()(FloorDashboard);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);