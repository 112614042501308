import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getUniqueTariffs, getTariffRecords, searchTariffRecords, updateTariffSingleRecord, createTariffRecord, deleteTariffRecord, uploadTariffRecords, wipeAndUploadTariffRecords, clearError } from "appRedux/actions/admin/tariff";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getDistributorList } from "appRedux/actions/admin/distributors";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
let retailerState = "";

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

    getInput = () => {
        const {
            dataIndex,
            record,
        } = this.props;

        function onChange(checked) {
        }

        function handleChange(value) {
            retailerState = value.toString().split(',').join('|');
        }

        if (this.props.inputType === 'statusSelection') {
            return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
        }
        else if (this.props.inputType === 'stateSelection') {
            return <Select
                style={{ width: '250px' }}
                placeholder="Please select State"
                onChange={handleChange}
            >
                <Option key="VIC">VIC</Option>
                <Option key="NSW">NSW</Option>
                <Option key="ACT">ACT</Option>
                <Option key="QLD">QLD</Option>
                <Option key="SA">SA</Option>
                <Option key="WA">WA</Option>
            </Select>;
        }
        return <Input style={{ minWidth: '150px' }} />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        function getInitialValue(dataIndex) {
            if (dataIndex == "isActive") {
                return record[dataIndex] == 1 ? true : false;
            }
            else if (dataIndex == "state") {
                retailerState = record[dataIndex];
                return record[dataIndex].split("|");
            }
            else {
                return record[dataIndex];
            }
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0, width: '100%' }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: getInitialValue(dataIndex),
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class ManageTariffs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            tariffTypeList: [],
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState:{
                "id": false,
                "tariff_code": false,
                "res_sme": false,
                "state": false,
                "distributor": false,
                "internal_tariff": false,
                "pricing_group": false,
                "tariff_class": false,
                "tariff_type": false,
                "child_tariff": false,
                "solar_rebate_scheme": false,
                "net_gross_tariff": false,
                "agl_unsupported_tariff": false,
                "origin_energy_unsupported_tariff": false,
                "powershop_unsupported_tariff": false,
                "powerdirect_unsupported_tariff": false,
                "momentum_unsupported_tariff": false,
                "alinta_energy_unsupported_tariff": false,
                "energy_australia_unsupported_tariff": false,
                "sumo_power_unsupported_tariff": false,
                "erm_unsupported_tariff": false,
                "pd_agl_unsupported_tariff": false,
                "lumo_energy_unsupported_tariff": false,
                "next_business_energy_unsupported_tariff": false,
                "actewagl_unsupported_tariff": false,
                "elysian_energy_unsupported_tariff": false,
                "testing_retailer_unsupported_tariff": false,
                "ovo_energy_unsupported_tariff": false,
                "created": false,
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <center>
                                <EditableContext.Consumer>
                                    {form => (
                                        <a
                                            onClick={() => this.save(form, record.key)}

                                        >
                                            <i className="icon icon-check-circle-o" />
                                        </a>
                                    )}
                                </EditableContext.Consumer>
                                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                                    <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                                </Popconfirm>
                            </center>
                        </span>
                    ) : (
                            <span>
                                <center>
                                    <a onClick={() => this.viewRecord(record)}>
                                        <i className="icon icon-search-new" />
                                    </a>
                                    <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                        <i className="icon icon-edit" />
                                    </a>
                                    <a style={{ marginLeft: 10, color:"#52c41a"  }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                        <i className="icon icon-copy" />
                                    </a>
                                    <a style={{ marginLeft: 10, color:"#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                        <i className="icon icon-close-circle" />
                                    </a>
                                    {/* <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a> */}
                                </center>
                            </span>
                        );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.state.localeCompare(b.state) },
            },
            {
                title: 'Distributor',
                dataIndex: 'distributor',
                key: 'distributor',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.distributor.localeCompare(b.distributor) },
            },
            {
                title: 'RES/SME',
                dataIndex: 'res_sme',
                key: 'res_sme',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
            },

            {
                title: 'Internal Tariff',
                dataIndex: 'internal_tariff',
                key: 'internal_tariff',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.internal_tariff.localeCompare(b.internal_tariff) },
            },
            {
                title: 'Pricing Group',
                dataIndex: 'pricing_group',
                key: 'pricing_group',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.pricing_group.localeCompare(b.pricing_group) },
            },
            {
                title: 'Tariff Class',
                dataIndex: 'tariff_class',
                key: 'tariff_class',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.tariff_class.localeCompare(b.tariff_class) },
            },
            {
                title: 'Tariff Code',
                dataIndex: 'tariff_code',
                key: 'tariff_code',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.tariff_code.localeCompare(b.tariff_code) },
            },
            //   {
            //     title: 'Updated Date',
            //     dataIndex: 'updated',
            //     key: 'updated',
            //     align: 'center',
            //     width: 150,
            //     sorter: (a, b) => a.updated - b.updated,
            //     render: (text, record) => {

            //     return text!="Invalid date" ? <spa>{text}</spa> : "-";
            //     },
            //   },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
          });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadTariffRecords(data);

        console.log(info);
        
        //swal.close();

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
          }

          
    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
          });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadTariffRecords(data);

        console.log(info);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
          }

          
    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created,"YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
            
            record = this.setUnsupportedValuesInPayloadForExport(record);
        });


        const fileName = "TARIFFS_" + moment().format("DDMMYYYY_HHmmss");
        
        exportToCSV(fileName,dataToExport);
    };
    
    disableAllFields = () => {
        
        this.setState({
            fieldState:{
                "id": true,
                "tariff_code": true,
                "res_sme": true,
                "state": true,
                "distributor": true,
                "internal_tariff": true,
                "pricing_group": true,
                "tariff_class": true,
                "tariff_type": true,
                "child_tariff": true,
                "solar_rebate_scheme": true,
                "net_gross_tariff": true,
                "agl_unsupported_tariff": true,
                "origin_energy_unsupported_tariff": true,
                "powershop_unsupported_tariff": true,
                "powerdirect_unsupported_tariff": true,
                "momentum_unsupported_tariff": true,
                "alinta_energy_unsupported_tariff": true,
                "energy_australia_unsupported_tariff": true,
                "sumo_power_unsupported_tariff": true,
                "erm_unsupported_tariff": true,
                "pd_agl_unsupported_tariff": true,
                "lumo_energy_unsupported_tariff": true,
                "next_business_energy_unsupported_tariff": true,
                "actewagl_unsupported_tariff": true,
                "elysian_energy_unsupported_tariff": true,
                "testing_retailer_unsupported_tariff": true,
                "ovo_energy_unsupported_tariff": true,
                "created": true,
            }
        })
    };

    enableAllFields = () => {
        
        this.setState({
            fieldState:{
                "id": false,
                "tariff_code": false,
                "res_sme": false,
                "state": false,
                "distributor": false,
                "internal_tariff": false,
                "pricing_group": false,
                "tariff_class": false,
                "tariff_type": false,
                "child_tariff": false,
                "solar_rebate_scheme": false,
                "net_gross_tariff": false,
                "agl_unsupported_tariff": false,
                "origin_energy_unsupported_tariff": false,
                "powershop_unsupported_tariff": false,
                "powerdirect_unsupported_tariff": false,
                "momentum_unsupported_tariff": false,
                "alinta_energy_unsupported_tariff": false,
                "energy_australia_unsupported_tariff": false,
                "sumo_power_unsupported_tariff": false,
                "erm_unsupported_tariff": false,
                "pd_agl_unsupported_tariff": false,
                "lumo_energy_unsupported_tariff": false,
                "next_business_energy_unsupported_tariff": false,
                "actewagl_unsupported_tariff": false,
                "elysian_energy_unsupported_tariff": false,
                "testing_retailer_unsupported_tariff": false,
                "ovo_energy_unsupported_tariff": false,
                "created": false,
            }
        })
    };

    // save(form, key) {
    //     form.validateFields((error, row) => {
            
    //     });
    // }

    next() {
        const currentStep = this.state.currentStep + 1;
        this.setState({ currentStep });
    }

    prev() {
        const currentStep = this.state.currentStep - 1;
        this.setState({ currentStep });
    }

    duplicateRecord(record) {

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState:{
                ...({created:true})
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteTariffRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtResSmeSearch;
        delete values.txtDistributorSearch;
        delete values.txtTariffCodeSearch;

        return values;
    };

    setUnsupportedValuesInPayloadForExport = (values) => {

        values.agl_unsupported_tariff = !isEmpty(values.agl_unsupported_tariff) ? "Unsupported" : "";
        values.origin_energy_unsupported_tariff = !isEmpty(values.origin_energy_unsupported_tariff) ? "Unsupported" : "";
        values.powershop_unsupported_tariff = !isEmpty(values.powershop_unsupported_tariff) ? "Unsupported" : "";
        values.powerdirect_unsupported_tariff = !isEmpty(values.powerdirect_unsupported_tariff) ? "Unsupported" : "";
        values.momentum_unsupported_tariff = !isEmpty(values.momentum_unsupported_tariff) ? "Unsupported" : "";
        values.alinta_energy_unsupported_tariff = !isEmpty(values.alinta_energy_unsupported_tariff) ? "Unsupported" : "";
        values.energy_australia_unsupported_tariff = !isEmpty(values.energy_australia_unsupported_tariff) ? "Unsupported" : "";
        values.sumo_power_unsupported_tariff = !isEmpty(values.sumo_power_unsupported_tariff) ? "Unsupported" : "";
        values.erm_unsupported_tariff = !isEmpty(values.erm_unsupported_tariff) ? "Unsupported" : "";
        values.pd_agl_unsupported_tariff = !isEmpty(values.pd_agl_unsupported_tariff) ? "Unsupported" : "";
        values.lumo_energy_unsupported_tariff = !isEmpty(values.lumo_energy_unsupported_tariff) ? "Unsupported" : "";
        values.next_business_energy_unsupported_tariff = !isEmpty(values.next_business_energy_unsupported_tariff) ? "Unsupported" : "";
        values.actewagl_unsupported_tariff = !isEmpty(values.actewagl_unsupported_tariff) ? "Unsupported" : "";
        values.elysian_energy_unsupported_tariff = !isEmpty(values.elysian_energy_unsupported_tariff) ? "Unsupported" : "";
        values.ovo_energy_unsupported_tariff = !isEmpty(values.ovo_energy_unsupported_tariff) ? "Unsupported" : "";
        values.testing_retailer_unsupported_tariff = !isEmpty(values.testing_retailer_unsupported_tariff) ? "Unsupported" : "";

        return values;
    };

    setUnsupportedValuesInPayload = (values) => {

        values.agl_unsupported_tariff = !values.agl_unsupported_tariff ? "Unsupported" : "";
        values.origin_energy_unsupported_tariff = !values.origin_energy_unsupported_tariff ? "Unsupported" : "";
        values.powershop_unsupported_tariff = !values.powershop_unsupported_tariff ? "Unsupported" : "";
        values.powerdirect_unsupported_tariff = !values.powerdirect_unsupported_tariff ? "Unsupported" : "";
        values.momentum_unsupported_tariff = !values.momentum_unsupported_tariff ? "Unsupported" : "";
        values.alinta_energy_unsupported_tariff = !values.alinta_energy_unsupported_tariff ? "Unsupported" : "";
        values.energy_australia_unsupported_tariff = !values.energy_australia_unsupported_tariff ? "Unsupported" : "";
        values.sumo_power_unsupported_tariff = !values.sumo_power_unsupported_tariff ? "Unsupported" : "";
        values.erm_unsupported_tariff = !values.erm_unsupported_tariff ? "Unsupported" : "";
        values.pd_agl_unsupported_tariff = !values.pd_agl_unsupported_tariff ? "Unsupported" : "";
        values.lumo_energy_unsupported_tariff = !values.lumo_energy_unsupported_tariff ? "Unsupported" : "";
        values.next_business_energy_unsupported_tariff = !values.next_business_energy_unsupported_tariff ? "Unsupported" : "";
        values.actewagl_unsupported_tariff = !values.actewagl_unsupported_tariff ? "Unsupported" : "";
        values.elysian_energy_unsupported_tariff = !values.elysian_energy_unsupported_tariff ? "Unsupported" : "";
        values.ovo_energy_unsupported_tariff = !values.ovo_energy_unsupported_tariff ? "Unsupported" : "";
        values.testing_retailer_unsupported_tariff = !values.testing_retailer_unsupported_tariff ? "Unsupported" : "";

        return values;
    };
    

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if(!err)
            {
                values = this.removeSearchValuesFromPayload(values);
                values = this.setUnsupportedValuesInPayload(values);
                console.log(values);

                this.setState({ 
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createTariffRecord(values);
                console.log("Add Data");
            }

            
        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values)
            delete values.created;

            values.id = this.state.singleRecordDetails.id;

            values = this.removeSearchValuesFromPayload(values);
            values = this.setUnsupportedValuesInPayload(values);
            console.log(values);

            this.setState({ 
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateTariffSingleRecord(values);
            console.log("Update Data");
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        this.props.getDistributorList();
        this.props.getTariffRecords();
        this.props.getUniqueTariffs();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        console.log(values);

            const filtersForSearch = {
                ...(values.txtDistributorSearch != "-1" && {distributor: values.txtDistributorSearch}),
                ...(values.txtStateSearch != "-1" && {state: values.txtStateSearch}),
                ...(values.txtResSmeSearch != "-1" && {res_sme: values.txtResSmeSearch}),
                ...(values.txtTariffCodeSearch != "-1" && {tariff_code: values.txtTariffCodeSearch}),
            
            }
            this.setState({ isSearching: true });

            if(!isEmpty(filtersForSearch))
                this.props.searchTariffRecords(filtersForSearch);
            else
            {
                this.props.getTariffRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({ 
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getTariffRecords();
    };

    checkColoumnType = (field) => {
        switch (field) {
            case 'isActive': return "statusSelection";
            case 'state': return "stateSelection";
            default: return "text";
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.tariffRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.tariffRecords;

            this.setState({ 
                loading: false,
                isSearching : false });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({ 
                loading: false,
                isSearching: false});
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getTariffRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getTariffRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const components = {
            body: {
                cell: EditableCell,
            },
        };

        console.log("Tariffs",this.props.tariffList);

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: this.checkColoumnType(col.dataIndex),
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title="Manage Tariffs" />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search Tariff Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="VIC">Victoria</Option>
                                                            <Option value="NSW">New South Wales</Option>
                                                            <Option value="ACT">Australian Capital Territory</Option>
                                                            <Option value="QLD">Queensland</Option>
                                                            <Option value="SA">South Australia</Option>
                                                            <Option value="WA">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="RES/SME" >
                                                    {getFieldDecorator('txtResSmeSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Distributor" >
                                                    {getFieldDecorator('txtDistributorSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Distributor"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Distributors</Option>
                                                            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                                                return <option key={key} value={distributor.distributorName}>{distributor.distributorName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Tariff Code" >
                                                    {getFieldDecorator('txtTariffCodeSearch', {
                                                    initialValue: "",
                                                    })(
                                                        <Input style={{ width: '100%' }} placeholder="Enter Tariff Code" />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} Tariff Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            <Row>
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Created Date" >
                                                            {getFieldDecorator('created', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created,"YYYY-MM-DD HH:mm:ss") : moment(),
                                                            rules: [{ required: true, message: 'Please select Created Date!' }],
                                                            })(
                                                            <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                            )}
                                                        </FormItem>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="State" >
                                                            {getFieldDecorator('state', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.state : undefined,
                                                            rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                disabled={this.state.fieldState.state}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select State"
                                                                >
                                                                    <Option key="VIC">Victoria</Option>
                                                                    <Option key="NSW">New South Wales</Option>
                                                                    <Option key="ACT">Australian Capital Territory</Option>
                                                                    <Option key="QLD">Queensland</Option>
                                                                    <Option key="SA">South Australia</Option>
                                                                    <Option key="WA">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="RES/SME" >
                                                            {getFieldDecorator('res_sme', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.res_sme : undefined,
                                                            rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                disabled={this.state.fieldState.res_sme}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="RES">RES</Option>
                                                                    <Option key="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Distributor" >
                                                            {getFieldDecorator('distributor', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.distributor : undefined,
                                                            rules: [{ required: true, message: 'Please select a Distributor!' }],
                                                            })(
                                                                <Select
                                                                disabled={this.state.fieldState.distributor}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Distributor"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                                                        return <option key={key} value={distributor.distributorName}>{distributor.distributorName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Tariff Code" >
                                                            {getFieldDecorator('tariff_code', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.tariff_code : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.tariff_code} style={{ width: '100%' }} placeholder="Enter Tariff Code" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Internal Tariff" >
                                                            {getFieldDecorator('internal_tariff', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.internal_tariff : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.internal_tariff} style={{ width: '100%' }} placeholder="Enter Internal Tariff" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Pricing Group" >
                                                            {getFieldDecorator('pricing_group', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.pricing_group : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.pricing_group} style={{ width: '100%' }} placeholder="Enter Pricing Group" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Tariff Class" >
                                                            {getFieldDecorator('tariff_class', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.tariff_class : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.tariff_class} style={{ width: '100%' }} placeholder="Enter Tariff Class" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Tariff Type" >
                                                            {getFieldDecorator('tariff_type', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.tariff_type : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.tariff_type} style={{ width: '100%' }} placeholder="Enter Tariff Type" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Child Tariff" >
                                                            {getFieldDecorator('child_tariff', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.child_tariff : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.child_tariff} style={{ width: '100%' }} placeholder="Enter Child Tariff" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Solar Rebate Scheme" >
                                                            {getFieldDecorator('solar_rebate_scheme', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_rebate_scheme : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_rebate_scheme} style={{ width: '100%' }} placeholder="Enter Solar Rebate Scheme" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Net Gross Tariff" >
                                                            {getFieldDecorator('net_gross_tariff', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.net_gross_tariff : "",
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.net_gross_tariff} style={{ width: '100%' }} placeholder="Enter Net Gross Tariff" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Panel>
                                        <Panel header="Retailer Availability Details" key="2">
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="AGL Supported Tariff" >
                                                            {getFieldDecorator('agl_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.agl_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.agl_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Origin Energy Supported Tariff" >
                                                            {getFieldDecorator('origin_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.origin_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.origin_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Powershop Supported Tariff" >
                                                            {getFieldDecorator('powershop_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.powershop_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.powershop_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Powerdirect Supported Tariff" >
                                                            {getFieldDecorator('powerdirect_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.powerdirect_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.powerdirect_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Momentum Supported Tariff" >
                                                            {getFieldDecorator('momentum_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.momentum_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.momentum_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Alinta Energy Supported Tariff" >
                                                            {getFieldDecorator('alinta_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.alinta_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.alinta_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Energy Australia Supported Tariff" >
                                                            {getFieldDecorator('energy_australia_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.energy_australia_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.energy_australia_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Sumo Power Supported Tariff" >
                                                            {getFieldDecorator('sumo_power_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.sumo_power_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.sumo_power_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="ERM Supported Tariff" >
                                                            {getFieldDecorator('erm_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.erm_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.erm_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="PD AGL Supported Tariff" >
                                                            {getFieldDecorator('pd_agl_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.pd_agl_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.pd_agl_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Lumo Energy Supported Tariff" >
                                                            {getFieldDecorator('lumo_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.lumo_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.lumo_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Next Business Energy Supported Tariff" >
                                                            {getFieldDecorator('next_business_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.next_business_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.next_business_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Actew AGL Supported Tariff" >
                                                            {getFieldDecorator('actewagl_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.actewagl_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.actewagl_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Elysian Energy Supported Tariff" >
                                                            {getFieldDecorator('elysian_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.elysian_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.elysian_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="OVO Energy Supported Tariff" >
                                                            {getFieldDecorator('ovo_energy_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.ovo_energy_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.ovo_energy_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Testing Retailer Supported Tariff" >
                                                            {getFieldDecorator('testing_retailer_unsupported_tariff', {
                                                            valuePropName: 'checked',
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? isEmpty(this.state.singleRecordDetails.testing_retailer_unsupported_tariff) ? true : false : false,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Switch disabled={this.state.fieldState.testing_retailer_unsupported_tariff} checkedChildren="Supported" unCheckedChildren="Unsupported" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title="Tariff Details">
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus"/>  Add New Tariff Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float:"right" }}>
                                <Icon type="download"/> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload"/> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe} style={{marginLeft:"10px"}}>
                                <Button type="danger">
                                    <Icon type="upload"/> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <EditableContext.Provider value={this.props.form}>
                                <Table
                                    components={components}
                                    rowClassName="editable-row"
                                    className="gx-table-responsive"
                                    {...this.state}
                                    pagination={{ pageSize: 10 }}
                                    columns={columns}
                                    dataSource={dataSource} />
                            </EditableContext.Provider>
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show= {this.state.showWaiting}
                    title= {'Please wait..'}
                    buttons= {false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,
    distributorList: state.distributors.distributorList,

    tariffList: state.tariff.tariffList,
    tariffRecords: state.tariff.resultList,
    recordUpdateStatus: state.tariff.recordUpdateStatus,
    error: state.tariff.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),
    getDistributorList: (data) => dispath(getDistributorList(data)),
    getUniqueTariffs:() => dispath(getUniqueTariffs()),

    getTariffRecords: () => dispath(getTariffRecords()),
    searchTariffRecords: (data) => dispath(searchTariffRecords(data)),
    updateTariffSingleRecord: (data) => dispath(updateTariffSingleRecord(data)),
    createTariffRecord: (data) => dispath(createTariffRecord(data)),
    deleteTariffRecord: (data) => dispath(deleteTariffRecord(data)),
    uploadTariffRecords: (data) => dispath(uploadTariffRecords(data)),
    wipeAndUploadTariffRecords: (data) => dispath(wipeAndUploadTariffRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManageTariffs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);