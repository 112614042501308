import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const saveFiveWorkingDayStats = fiveWorkingDaysaleStats => ({
    type: 'SAVE_FIVE_WORKING_DAY_SALES_STATS',
    fiveWorkingDaysaleStats,
});

export const saveThreeWeekStats = threeWeekDaySaleStats => ({
    type: 'SAVE_THREE_WEEK_SALES_STATS',
    threeWeekDaySaleStats,
});

export const saveSalesPerHourList = salesPerHourList => ({
    type: 'SAVE_SALES_PER_HOUR_LIST',
    salesPerHourList,
});

export const saveSalesSummaryData = salesSummaryData => ({
    type: 'SAVE_SALES_SUMMARY_DATA',
    salesSummaryData,
});

export const saveFetchStatus = fetchStatus => ({
    type: 'SAVE_FETCH_STATUS',
    fetchStatus,
});

export const saveAgentSalesSummary = agentSaleSummary => ({
    type: 'SAVE_AGENT_SALE_SUMMARY',
    agentSaleSummary,
});

export const saveElecRetailerSummary = elecRetailerStats => ({
    type: 'SAVE_ELEC_RETAILER_SUMMARY',
    elecRetailerStats,
});

export const saveGasRetailerSummary = gasRetailerStats => ({
    type: 'SAVE_GAS_RETAILER_SUMMARY',
    gasRetailerStats,
});

export const saveMoveInTransferSummary = moveInTransferStats => ({
    type: 'SAVE_MOVE_IN_TRANSFER_SUMMARY',
    moveInTransferStats,
});

export const saveElecProductSummary = elecProductStats => ({
    type: 'SAVE_ELEC_PRODUCT_SUMMARY',
    elecProductStats,
});

export const saveGasProductSummary = gasProductStats => ({
    type: 'SAVE_GAS_PRODUCT_SUMMARY',
    gasProductStats,
});

export const saveSalesDistributionStats = salesDistributionStats => ({
    type: 'SAVE_SALES_DISTRIBUTION_STATS',
    salesDistributionStats,
});

export const saveDemographicStats = demographicStats => ({
    type: 'SAVE_DEMOGRAPHIC_STATS',
    demographicStats,
});

export const saveStatusList = statusList => ({
    type: 'SAVE_STATUS_LIST',
    statusList,
});

export const saveLeadVolumeSummary = leadVolumeSummary => ({
    type: 'SAVE_LEAD_VOLUME_SUMMARY',
    leadVolumeSummary,
});


export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

function enumerateDaysBetweenDates(startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate, dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {

        if(now.day()!=0)
        {
            dates.push(now.format('YYYY-MM-DD'));
        }

        //dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }
    return dates;
};

//Starting Date and end Date required (fromDate and toDate)
export const getSalesDistribution = (data) => dispath => {
    dispath(saveFetchStatus("Fetching"));

    if (data === undefined) {
        data = {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        }
    }
    else {
        data.fromDate = moment(data.fromDate).format('YYYY-MM-DD');
        data.toDate = moment(data.toDate).format('YYYY-MM-DD');
    }

    axios.post(apidetails.API_URL + 'salesInformation/', qs.stringify(data), config)
        .then(res => {

            var allSales = res.data.salesRecords;

            var salesDistributionStats = {
                totalSales: 0,
                elecTotal: 0,
                gasTotal: 0,
                retailerDetails: [],
            };

            var retailerNames = ["ACTEWAGL", "AGL", "ALINTA ENERGY", "LUMO ENERGY", "MOMENTUM", "NEXT BUSINESS ENERGY", "ORIGIN ENERGY", "POWERDIRECT", "POWERSHOP", "SUMO POWER", "OVO ENERGY"];

            retailerNames.map((retailer, key) => {

                var retailerDetails = {
                    name: retailer,
                    totalSales: 0,
                    elec: {
                        totalSales: 0,
                        residential: {
                            totalSales: 0,
                            retentionSales: 0,
                            acquisitionSales: 0,
                        },
                        business: {
                            totalSales: 0,
                            retentionSales: 0,
                            acquisitionSales: 0,
                        },
                    },
                    gas: {
                        totalSales: 0,
                        residential: {
                            totalSales: 0,
                            retentionSales: 0,
                            acquisitionSales: 0,
                        },
                        business: {
                            totalSales: 0,
                            retentionSales: 0,
                            acquisitionSales: 0,
                        },
                    },
                };

                salesDistributionStats.retailerDetails.push(retailerDetails);

            });

            allSales.map((sale, key) => {

                try {

                    var elecRetailerName = sale.NewElectricityRetailer["_text"].toUpperCase();

                    var elecSalesDistributionData = salesDistributionStats.retailerDetails.find(data => data.name == elecRetailerName);

                }
                catch (err) { }

                try {

                    var gasRetailerName = sale.NewGasRetailer["_text"].toUpperCase();

                    var gasSalesDistributionData = salesDistributionStats.retailerDetails.find(data => data.name == gasRetailerName);

                }
                catch (err) { }

                try {

                    if (sale.NMICode["_text"] != 0) // NMI
                    {
                        salesDistributionStats.totalSales = salesDistributionStats.totalSales + 1;
                        salesDistributionStats.elecTotal = salesDistributionStats.elecTotal + 1;

                        elecSalesDistributionData.totalSales = elecSalesDistributionData.totalSales + 1;
                        elecSalesDistributionData.elec.totalSales = elecSalesDistributionData.elec.totalSales + 1;

                        if ((sale.BusinessorResidential["_text"] == "Residential")) // RESI
                        {
                            elecSalesDistributionData.elec.residential.totalSales = elecSalesDistributionData.elec.residential.totalSales + 1;

                            if ((sale["NMIAcq_x002F_Ret"]["_text"] == "Acquisition")) // Acquisition
                            {
                                elecSalesDistributionData.elec.residential.acquisitionSales = elecSalesDistributionData.elec.residential.acquisitionSales + 1;
                            }
                            else if ((sale["NMIAcq_x002F_Ret"]["_text"] == "Retention")) // Retention
                            {
                                elecSalesDistributionData.elec.residential.retentionSales = elecSalesDistributionData.elec.residential.retentionSales + 1;
                            }
                        }
                        else if ((sale.BusinessorResidential["_text"] == "Business")) // SME
                        {
                            elecSalesDistributionData.elec.business.totalSales = elecSalesDistributionData.elec.business.totalSales + 1;

                            if ((sale["NMIAcq_x002F_Ret"]["_text"] == "Acquisition")) // Acquisition
                            {
                                elecSalesDistributionData.elec.business.acquisitionSales = elecSalesDistributionData.elec.business.acquisitionSales + 1;
                            }
                            else if ((sale["NMIAcq_x002F_Ret"]["_text"] == "Retention")) // Retention
                            {
                                elecSalesDistributionData.elec.business.retentionSales = elecSalesDistributionData.elec.business.retentionSales + 1;
                            }
                        }

                        //RETENTION/ACQUISITION //384
                    }

                }
                catch (err) { }

                try {

                    if ((sale.MIRNNumber["_text"] != 0)) // MIRN
                    {
                        salesDistributionStats.totalSales = salesDistributionStats.totalSales + 1;
                        salesDistributionStats.gasTotal = salesDistributionStats.gasTotal + 1;

                        gasSalesDistributionData.totalSales = gasSalesDistributionData.totalSales + 1;
                        gasSalesDistributionData.gas.totalSales = gasSalesDistributionData.gas.totalSales + 1;

                        //RESI/SME
                        if ((sale.BusinessorResidential["_text"] == "Residential")) // RESI
                        {
                            gasSalesDistributionData.gas.residential.totalSales = gasSalesDistributionData.gas.residential.totalSales + 1;

                            if ((sale["MIRNAcq_x002F_Ret"]["_text"] == "Acquisition")) // Acquisition
                            {
                                gasSalesDistributionData.gas.residential.acquisitionSales = gasSalesDistributionData.gas.residential.acquisitionSales + 1;
                            }
                            else if ((sale["MIRNAcq_x002F_Ret"]["_text"] == "Retention")) // Retention
                            {
                                gasSalesDistributionData.gas.residential.retentionSales = gasSalesDistributionData.gas.residential.retentionSales + 1;
                            }
                        }
                        else if ((sale.BusinessorResidential["_text"] == "Business")) // SME
                        {
                            gasSalesDistributionData.gas.business.totalSales = gasSalesDistributionData.gas.business.totalSales + 1;

                            if ((sale["MIRNAcq_x002F_Ret"]["_text"] == "Acquisition")) // Acquisition
                            {
                                gasSalesDistributionData.gas.business.acquisitionSales = gasSalesDistributionData.gas.business.acquisitionSales + 1;
                            }
                            else if ((sale["MIRNAcq_x002F_Ret"]["_text"] == "Retention")) // Retention
                            {
                                gasSalesDistributionData.gas.business.retentionSales = gasSalesDistributionData.gas.business.retentionSales + 1;
                            }
                        }
                    }
                }
                catch (err) { }

            });

            dispath(saveSalesDistributionStats(salesDistributionStats));

            dispath(saveSalesDistributionStats(null));

            dispath(saveFetchStatus(null));

        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });


}

//Starting Date and end Date required (fromDate and toDate)
export const getSalesDataPerHour = (data) => dispath => {

    dispath(saveFetchStatus("Fetching"));

    if (data === undefined) {
        data = {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        }
    }
    else {
        data.fromDate = moment(data.fromDate).format('YYYY-MM-DD');
        data.toDate = moment(data.toDate).format('YYYY-MM-DD');
    }

    axios.post(apidetails.API_URL + 'salesInformation/', qs.stringify(data), config)
        .then(res => {
            var allSales = res.data.salesRecords;

            var thisWeekDaySales = res.data.currentWeekDayResults;
            var lastWeekDaySales = res.data.lastWeekDayResults;
            var weekBeforeLastDaySales = res.data.weekBeforeLastDayResults;
            var twoWeeksBeforeLastDaySales = res.data.twoWeeksBeforeLastDayResults;

            var lastFiveWorkingDaySales = res.data.lastFiveWorkingDayResults.results;

            var threeWeekStats = [];
            var lastFiveWorkingDayStats = [];
            var lastFiveWorkingDateList = enumerateDaysBetweenDates(res.data.lastFiveWorkingDayResults.startDate, res.data.lastFiveWorkingDayResults.endDate);

            var dateList = enumerateDaysBetweenDates(data.fromDate, data.toDate);

            //var dataGroupType = data.groupType == "Hourly" ? "Hourly" : "HalfHour" ;
            var dataGroupType = "Hourly";
            var hourStart = 8;
            var hourEnd = 20;
            var halfHours = (hourEnd - hourStart) * 2;
            var totalSalesCount = 0;
            var totalElecCount = 0;
            var totalGasCount = 0;

            var salesDataGroups = [];

            var agentList = []; //104
            var elecRetailerStats = []; //391
            var gasRetailerStats = []; //392
            var distributorStats = []; //421
            var elecProductStats = []; //185
            var gasProductStats = []; //216
            var moveInTransferStats = {
                moveIn: 0,
                transfer: 0,
            }; //408
            var resSmeStats = []; //407
            var fuelTypeStats = []; //200


            allSales.map((sale, key) => {

                //Agent Summary

                //var agentField =  sale.Fields.Field.find(data => data["_attributes"].FieldId == "104");
                var agentName = sale.SalesRepName["_text"];

                if (!(agentList.find(data => data.name == agentName))) {
                    var agentDetails = {
                        name: agentName,
                        scores: {
                            total: 0,
                            elec: 0,
                            gas: 0,
                        }
                    };

                    agentList.push(agentDetails);
                }

                var agentData = agentList.find(data => data.name == agentName);

                //Elec Retailer Summary

                var elecRetailerName = sale.NewElectricityRetailer["_text"].toUpperCase();
                if (!isEmpty(elecRetailerName) && (elecRetailerName != "NA") && !(elecRetailerStats.find(data => data.name == elecRetailerName))) {
                    var elecRetailerDetails = {
                        name: elecRetailerName,
                        elec: 0,
                    };

                    elecRetailerStats.push(elecRetailerDetails);
                }

                var elecRetailerData = elecRetailerStats.find(data => data.name == elecRetailerName);

                //Gas Retailer Summary

                var gasRetailerName = sale.NewGasRetailer["_text"].toUpperCase();
                if (!isEmpty(gasRetailerName) && (gasRetailerName != "NA") && !(gasRetailerStats.find(data => data.name == gasRetailerName))) {
                    var gasRetailerDetails = {
                        name: gasRetailerName,
                        gas: 0,
                    };

                    gasRetailerStats.push(gasRetailerDetails);
                }

                var gasRetailerData = gasRetailerStats.find(data => data.name == gasRetailerName);

                //Sales Stats Summary

                if (sale.NMICode["_text"] != 0) // Elec
                {
                    agentData.scores.elec = agentData.scores.elec + 1;
                    agentData.scores.total = agentData.scores.total + 1;

                    if (!isEmpty(elecRetailerData)) {
                        elecRetailerData.elec = elecRetailerData.elec + 1;
                    }
                }

                if ((sale.MIRNNumber["_text"] != 0)) // Gas
                {
                    agentData.scores.gas = agentData.scores.gas + 1;
                    agentData.scores.total = agentData.scores.total + 1;

                    if (!isEmpty(gasRetailerData)) {
                        gasRetailerData.gas = gasRetailerData.gas + 1;
                    }
                }

                //MoveIn or Transfer

                if ((sale["Move-inorTransfer"]["_text"] == "Move In")) // MoveIn
                {
                    moveInTransferStats.moveIn = moveInTransferStats.moveIn + 1;
                }
                else if ((sale["Move-inorTransfer"]["_text"] == "Transfer")) // Transfer
                {
                    moveInTransferStats.transfer = moveInTransferStats.transfer + 1;
                }

                //Elec Product Summary 

                try {
                    //console.log(sale.Fields.Field.find(data => data["_attributes"].FieldId == "185")["_attributes"].Value);
                    var elecProductName = sale.ElectricityProduct["_text"];;

                    if (!isEmpty(elecProductName) && (elecProductName != "NA") && !(elecProductStats.find(data => data.name == elecProductName))) {
                        var elecProductDetails = {
                            name: elecProductName,
                            retailer: sale.NewElectricityRetailer["_text"].toUpperCase(),
                            sales: 0,
                        };

                        elecProductStats.push(elecProductDetails);
                    }

                    var elecProductData = elecProductStats.find(data => data.name == elecProductName);

                    if (!isEmpty(elecProductData)) {
                        if ((sale.ElectricityProduct["_text"] == elecProductData.name)) // MoveIn
                        {
                            elecProductData.sales = elecProductData.sales + 1;
                        }
                    }
                }
                catch (err) {
                    //console.log(err);
                }

                //Gas Product Summary

                try {
                    var gasProductName = sale.GasProduct["_text"];
                    if (!isEmpty(gasProductName) && (gasProductName != "NA") && !(gasProductStats.find(data => data.name == gasProductName))) {
                        var gasProductDetails = {
                            name: gasProductName,
                            retailer: sale.NewGasRetailer["_text"].toUpperCase(),
                            sales: 0,
                        };

                        gasProductStats.push(gasProductDetails);
                    }

                    var gasProductData = gasProductStats.find(data => data.name == gasProductName);

                    if (!isEmpty(gasProductData)) {
                        if ((sale.GasProduct["_text"] == gasProductData.name)) // MoveIn
                        {
                            gasProductData.sales = gasProductData.sales + 1;
                        }
                    }
                }
                catch (err) {
                    //console.log(err);
                }


            });

            var leadSourceList = [];

            allSales.map((sale, key) => {
                var leadSource = sale.LeadSource["_text"].toLowerCase();

                if (!isEmpty(leadSource) && (leadSource != "") && (leadSource != "SDAU") && !(leadSourceList.find(data => data.name == leadSource))) {

                    var leadSourceDetails = {
                        name: leadSource,
                        sales: 0,
                    }

                    leadSourceList.push(leadSourceDetails);
                }
            });

            var groupCount = 0;

            var thisWeekDayTotalSalesCummulative = 0;
            var lastWeekDayTotalSalesCummulative = 0;
            var weekBeforeLastDayTotalSalesCummulative = 0;
            var twoWeeksBeforeLastDayTotalSalesCummulative = 0;
            var fiveWorkingDayTotalSales = []; 


            //lastFiveWorkingDaySales
            //lastFiveWorkingDayStats

            var currentDateFormatted = moment().format("YYYY-MM-DD");

            while (groupCount < (hourEnd - hourStart)) {

                

                var salesCount = 0;
                var elecSalesCount = 0; //Field Code 27
                var gasSalesCount = 0; //Field Code 41
                //var allSalesData = [];

                var currentActualHour = moment().format("H");

                var thisWeekDayTotalSales = 0;
                var lastWeekDayTotalSales = 0;
                var weekBeforeLastDayTotalSales = 0;
                var twoWeeksBeforeLastDayTotalSales = 0;

                leadSourceList.map((leadSource, key) => {
                    leadSource.sales = 0;
                });

                var fiveWorkingDayCount = 0;

                var fiveWorkingDaySales = []; 

                

                while (fiveWorkingDayCount < lastFiveWorkingDateList.length) {

                    var tempElec = Object.values(lastFiveWorkingDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    var tempGas = Object.values(lastFiveWorkingDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;

                    // var tempObj = {
                    //     elec: Object.values(lastFiveWorkingDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length,
                    //     gas: Object.values(lastFiveWorkingDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastFiveWorkingDateList[fiveWorkingDayCount] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length,
                    // }

                    // tempObj.totalSales = tempObj.elec + tempObj.gas;

                    var tempObj = {
                        totalSales: tempElec + tempGas,
                    }
                    
                    fiveWorkingDaySales.push(tempObj)

                    if(groupCount == 0)
                    {

                        var tempObjFiveWorkingDayTotalSales = {
                            totalElec: 0,
                            totalGas: 0,
                            totalSales: 0,
                        }

                        fiveWorkingDayTotalSales.push(tempObjFiveWorkingDayTotalSales); 
                    } 

                    if(currentDateFormatted == lastFiveWorkingDateList[fiveWorkingDayCount])
                    {
                        // console.log("in");
                        if(fiveWorkingDaySales[fiveWorkingDayCount].totalSales != 0 )
                            fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales = fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales + fiveWorkingDaySales[fiveWorkingDayCount].totalSales;
                        else
                        {
                            if(groupCount == 0)
                                fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales = fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales + fiveWorkingDaySales[fiveWorkingDayCount].totalSales;
                            else
                            {
                                fiveWorkingDaySales[fiveWorkingDayCount].totalSales = null;
                                fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales = null;
                            }                            
                        }  

                        if((hourStart + groupCount) > currentActualHour)
                        {
                            fiveWorkingDaySales[fiveWorkingDayCount].totalSales = null;
                            fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales = null;
                        }
                    }
                    else
                    {
                        fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales = fiveWorkingDayTotalSales[fiveWorkingDayCount].totalSales + fiveWorkingDaySales[fiveWorkingDayCount].totalSales;
                    }

                    fiveWorkingDayCount++;
                }

                var tempFiveDaySaleStatsComponenet = {
                    type: dataGroupType,
                    section: `${(hourStart + groupCount).toString().length == 1 ? `0${(hourStart + groupCount)}` : `${(hourStart + groupCount)}`}:00`,
                    [lastFiveWorkingDateList[0]] : fiveWorkingDaySales[0].totalSales,
                    [lastFiveWorkingDateList[1]] : fiveWorkingDaySales[1].totalSales,
                    [lastFiveWorkingDateList[2]] : fiveWorkingDaySales[2].totalSales,
                    [lastFiveWorkingDateList[3]] : fiveWorkingDaySales[3].totalSales,
                    [lastFiveWorkingDateList[4]] : fiveWorkingDaySales[4].totalSales,
                    'Day0Total' : fiveWorkingDayTotalSales[0].totalSales,
                    'Day1Total' : fiveWorkingDayTotalSales[1].totalSales,
                    'Day2Total' : fiveWorkingDayTotalSales[2].totalSales,
                    'Day3Total' : fiveWorkingDayTotalSales[3].totalSales,
                    'Day4Total' : fiveWorkingDayTotalSales[4].totalSales,
                }

                lastFiveWorkingDayStats.push(tempFiveDaySaleStatsComponenet);

                var count = 0;

                var tempWeek1Total = 0;
                var tempWeek2Total = 0;
                var tempWeek3Total = 0;
                var tempWeek4Total = 0;

                while (count < dateList.length) {

                    thisWeekDayTotalSales = thisWeekDayTotalSales + Object.values(thisWeekDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    thisWeekDayTotalSales = thisWeekDayTotalSales + Object.values(thisWeekDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;
                    
                    if(currentDateFormatted == dateList[count])
                    {
                        if(thisWeekDayTotalSales != 0 )
                            thisWeekDayTotalSalesCummulative = thisWeekDayTotalSalesCummulative + thisWeekDayTotalSales;
                        else
                        {
                            if(groupCount == 0)
                                thisWeekDayTotalSalesCummulative = thisWeekDayTotalSalesCummulative + thisWeekDayTotalSales;
                            else
                            {
                                thisWeekDayTotalSales = null;
                                thisWeekDayTotalSalesCummulative = null;
                            }                            
                        }  

                        if((hourStart + groupCount) > currentActualHour)
                        {
                            thisWeekDayTotalSales = null;
                            thisWeekDayTotalSalesCummulative = null;
                        }
                    }
                    else
                    {
                        thisWeekDayTotalSalesCummulative = thisWeekDayTotalSalesCummulative + thisWeekDayTotalSales;
                    }

                    

                    var lastWeekDayFormatted = moment(dateList[count],"YYYY-MM-DD").add(-7,"days").format("YYYY-MM-DD");
                    try{
                        lastWeekDayTotalSales = lastWeekDayTotalSales + Object.values(lastWeekDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastWeekDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastWeekDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    }
                    catch(err){}
                    try{
                        lastWeekDayTotalSales = lastWeekDayTotalSales + Object.values(lastWeekDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(lastWeekDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(lastWeekDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;
                    }
                    catch(err){}
                    lastWeekDayTotalSalesCummulative = lastWeekDayTotalSalesCummulative + lastWeekDayTotalSales;

                    var weekBeforeLastDayFormatted = moment(dateList[count],"YYYY-MM-DD").add(-14,"days").format("YYYY-MM-DD");
                    try{
                        weekBeforeLastDayTotalSales = weekBeforeLastDayTotalSales + Object.values(weekBeforeLastDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(weekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(weekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    }
                    catch(err){}
                    try{
                        weekBeforeLastDayTotalSales = weekBeforeLastDayTotalSales + Object.values(weekBeforeLastDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(weekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(weekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;
                    }
                    catch(err){}
                    weekBeforeLastDayTotalSalesCummulative = weekBeforeLastDayTotalSalesCummulative + weekBeforeLastDayTotalSales;

                    var twoWeekBeforeLastDayFormatted = moment(dateList[count],"YYYY-MM-DD").add(-28,"days").format("YYYY-MM-DD");
                    try{
                        twoWeeksBeforeLastDayTotalSales = twoWeeksBeforeLastDayTotalSales + Object.values(twoWeeksBeforeLastDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(twoWeekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(twoWeekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    }
                    catch(err){}
                    try{
                        twoWeeksBeforeLastDayTotalSales = twoWeeksBeforeLastDayTotalSales + Object.values(twoWeeksBeforeLastDaySales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(twoWeekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(twoWeekBeforeLastDayFormatted + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;
                    }
                    catch(err){}
                    
                    twoWeeksBeforeLastDayTotalSalesCummulative = twoWeeksBeforeLastDayTotalSalesCummulative + twoWeeksBeforeLastDayTotalSales;
                    

                    

                    elecSalesCount = elecSalesCount + Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))).length;
                    // console.log("elec", elecSalesCount, moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss').format('YYYY-MM-DD H:mm:ss'),moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss').format('YYYY-MM-DD H:mm:ss') , Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))));
                    
                    gasSalesCount = gasSalesCount + Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))).length;
                    //console.log("gas", gasSalesCount, moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss').format('YYYY-MM-DD H:mm:ss'),moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss').format('YYYY-MM-DD H:mm:ss') , Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0))));
                    
                    salesCount = elecSalesCount + gasSalesCount;
                    //allSalesData.push(allSales.filter(sale => moment((sale.Fields.Field.find(data => data["_attributes"].FieldId == "397"))["_attributes"].Value).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DD H:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DD H:mm:ss')))));

                    leadSourceList.map((leadSource, key) => {

                        var leadSourceElecCount = Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0) && (sale.LeadSource["_text"].toLowerCase() == leadSource.name))).length;
                        //console.log(Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.NMICode["_text"] != 0))));
                        var leadSourceGasCount = Object.values(allSales.filter(sale => (moment(sale["SaleCompletionDate"]["_text"].replace("T00:00:00","") + "T" + sale["SaleCompletionTime"]["_text"]).within(moment.range(moment(dateList[count] + ' ' + (hourStart + groupCount) + ':00:00', 'YYYY-MM-DDTH:mm:ss'), moment(dateList[count] + ' ' + (hourStart + groupCount) + ':59:59', 'YYYY-MM-DDTH:mm:ss')))) && (sale.MIRNNumber["_text"] != 0) && (sale.LeadSource["_text"].toLowerCase() == leadSource.name))).length;
                        
                        leadSource.sales = leadSource.sales + leadSourceElecCount + leadSourceGasCount;
                    });

                    count++;
                }


                // thisWeekDayTotalSalesCummulative = thisWeekDayTotalSalesCummulative + tempWeek1Total;
                // lastWeekDayTotalSalesCummulative = lastWeekDayTotalSalesCummulative + lastWeekDayTotalSales;
                // weekBeforeLastDayTotalSalesCummulative = weekBeforeLastDayTotalSalesCummulative + weekBeforeLastDayTotalSales;
                // twoWeeksBeforeLastDayTotalSalesCummulative = twoWeeksBeforeLastDayTotalSalesCummulative + twoWeeksBeforeLastDayTotalSales;

                totalSalesCount = totalSalesCount + elecSalesCount + gasSalesCount;
                totalElecCount = totalElecCount + elecSalesCount;
                totalGasCount = totalGasCount + gasSalesCount;

                var salesDataComponent = {
                    type: dataGroupType,
                    section: (hourStart + groupCount) + " to " + (hourStart + groupCount + 1),
                    sales: salesCount,
                    elec: elecSalesCount,
                    gas: gasSalesCount,
                    //salesData: allSalesData,
                };

                leadSourceList.map((leadSource, key) => {
                    salesDataComponent[leadSource.name] = leadSource.sales;
                });

                salesDataGroups.push(salesDataComponent);               

                var tempThreeWeekStats = {
                    type: dataGroupType,
                    //section: (hourStart + groupCount) + " to " + (hourStart + groupCount + 1),
                    section: `${(hourStart + groupCount).toString().length == 1 ? `0${(hourStart + groupCount)}` : `${(hourStart + groupCount)}`}:00`,
                    thisWeek: thisWeekDayTotalSales,
                    lastWeek: lastWeekDayTotalSales,
                    weekBeforeLast: weekBeforeLastDayTotalSales,
                    twoWeeksBeforeLastDayTotalSales: twoWeeksBeforeLastDayTotalSales,
                    thisWeekCummulative: thisWeekDayTotalSalesCummulative,
                    lastWeekCummulative: lastWeekDayTotalSalesCummulative,
                    weekBeforeLastCummulative: weekBeforeLastDayTotalSalesCummulative,
                    twoWeeksBeforeLastDayTotalSalesCummulative: twoWeeksBeforeLastDayTotalSalesCummulative,
                }

                threeWeekStats.push(tempThreeWeekStats);

                groupCount++;
            }

            var salesSummaryData = {
                totalSales: totalSalesCount,
                totalElec: totalElecCount,
                totalGas: totalGasCount,
            };

            //console.log(salesSummaryData)

            !isEmpty(threeWeekStats) && dispath(saveThreeWeekStats(threeWeekStats));
            !isEmpty(lastFiveWorkingDayStats) && dispath(saveFiveWorkingDayStats(lastFiveWorkingDayStats));

            
            dispath(saveSalesPerHourList(salesDataGroups));
            dispath(saveSalesSummaryData(salesSummaryData));
            dispath(saveAgentSalesSummary(agentList));
            dispath(saveElecRetailerSummary(elecRetailerStats));
            dispath(saveGasRetailerSummary(gasRetailerStats));
            dispath(saveMoveInTransferSummary(moveInTransferStats));
            dispath(saveElecProductSummary(elecProductStats));
            dispath(saveGasProductSummary(gasProductStats));

            dispath(saveThreeWeekStats(null))
            dispath(saveFiveWorkingDayStats(null))
            dispath(saveSalesPerHourList(null));
            dispath(saveSalesSummaryData(null));
            dispath(saveAgentSalesSummary(null));
            dispath(saveElecRetailerSummary(null));
            dispath(saveGasRetailerSummary(null));
            dispath(saveMoveInTransferSummary(null));
            dispath(saveElecProductSummary(null));
            dispath(saveGasProductSummary(null));

            dispath(saveFetchStatus(null));
        })
        .catch(err => {
            console.log(err);
            dispath(error(err.message || 'ERROR'));
        });

}

export const getDemographicStats = (data) => dispath => {

    dispath(saveFetchStatus("Fetching"));

    if (data === undefined) {
        data = {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        }
    }
    else {
        data.fromDate = moment(data.fromDate).format('YYYY-MM-DD');
        data.toDate = moment(data.toDate).format('YYYY-MM-DD');
    }

    axios.post(apidetails.API_URL + 'salesInformation/leads', qs.stringify(data), config)
        .then(res => {

            var allLeads = res.data.leadList;

            var uniqueCustomers = [];

            var demographicStats = {
                hasData: false,
                gender: {
                    male: 0,
                    female: 0
                },
                age: {
                    "18to25": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "26to35": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "36to45": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "46to55": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "56to65": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "66to75": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "76to85": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                    "86to95": {
                        total: 0,
                        male: 0,
                        female: 0,
                    },
                }
            };

            allLeads.map((lead, key) => {
                //let fieldList = lead.Fields.Field;
                let StatusId = lead.Status["_text"];
                var considerLead = false;

                var uniqueCustomerCombination = "";

                //First Name
                try {
                    uniqueCustomerCombination = uniqueCustomerCombination + "_" + lead.FirstName["_text"];
                }
                catch (err) { }

                //Last Name
                try {
                    uniqueCustomerCombination = uniqueCustomerCombination + "_" + lead.Surname["_text"];
                }
                catch (err) { }

                //Email
                try {
                    uniqueCustomerCombination = uniqueCustomerCombination + "_" + lead["Email_x0028_M_x0029_"]["_text"];
                }
                catch (err) { }

                if (!isEmpty(uniqueCustomerCombination) && (uniqueCustomerCombination != "") && !(uniqueCustomers.find(data => data == uniqueCustomerCombination))) {
                    uniqueCustomers.push(uniqueCustomerCombination);

                    if(!isEmpty(data.stateList))
                    {
                        try{
                            if(!isEmpty(data.stateList.find(data => data == lead["State_x0028_Supply_x0029_"]["_text"])))
                            {
                                considerLead = true;
                            }
                        }
                        catch(err)
                        {

                        }
                    }
                    else
                    {
                        considerLead = true;
                    }

                    // if(!isEmpty(data.saleOnly))
                    // {
                    //     //Filter by sales (if Required)
                    //     if(data.saleOnly)
                    //     {
                    //         if(isEmpty(fieldList.find(data => data["_attributes"].FieldId == "397")))
                    //             considerLead = false;
                    //     }
                    // }

                    // //Filter by status (if Required)
                    // if(!isEmpty(data.statusId))
                    // {
                    //     if(StatusId != data.statusId && data.statusId != -1)
                    //     {
                    //         considerLead = false;
                    //     }
                    // }            

                    if (considerLead) {
                        demographicStats.hasData = true;

                        try {
                            //Check Age //206 
                            var dateOfBirth = moment(lead["DateOfBirth_x0028_Date_x0029_"]["_text"]);
                            var ageDurationObj = Moment.duration(moment().diff(dateOfBirth));
                            var years = ageDurationObj.years()
                            var ageCategory = "";

                            if (years <= 25) {
                                demographicStats.age["18to25"].total = demographicStats.age["18to25"].total + 1;
                                ageCategory = "18to25";
                            }
                            else if (years <= 35) {
                                demographicStats.age["26to35"].total = demographicStats.age["26to35"].total + 1;
                                ageCategory = "26to35";
                            }
                            else if (years <= 45) {
                                demographicStats.age["36to45"].total = demographicStats.age["36to45"].total + 1;
                                ageCategory = "36to45";
                            }
                            else if (years <= 55) {
                                demographicStats.age["46to55"].total = demographicStats.age["46to55"].total + 1;
                                ageCategory = "46to55";
                            }
                            else if (years <= 65) {
                                demographicStats.age["56to65"].total = demographicStats.age["56to65"].total + 1;
                                ageCategory = "56to65";
                            }
                            else if (years <= 75) {
                                demographicStats.age["66to75"].total = demographicStats.age["66to75"].total + 1;
                                ageCategory = "66to75";
                            }
                            else if (years <= 85) {
                                demographicStats.age["76to85"].total = demographicStats.age["76to85"].total + 1;
                                ageCategory = "76to85";
                            }
                            else if (years <= 95) {
                                demographicStats.age["86to95"].total = demographicStats.age["86to95"].total + 1;
                                ageCategory = "86to95";
                            }

                            try {
                                //console.log("Lead Considered");
                                //Check Gender //79-Title Mr, Mrs, Ms, Lady, Rev, Sir, Dr, Miss
                                var leadTitle = lead.Title["_text"];

                                switch (leadTitle) {
                                    case "Mr":
                                        demographicStats.gender.male = demographicStats.gender.male + 1;
                                        demographicStats.age[ageCategory].male = demographicStats.age[ageCategory].male + 1;
                                        break;
                                    case "Mrs":
                                        demographicStats.gender.female = demographicStats.gender.female + 1;
                                        demographicStats.age[ageCategory].female = demographicStats.age[ageCategory].female + 1;
                                        break;
                                    case "Ms":
                                        demographicStats.gender.female = demographicStats.gender.female + 1;
                                        demographicStats.age[ageCategory].female = demographicStats.age[ageCategory].female + 1;
                                        break;
                                    case "Lady":
                                        demographicStats.gender.female = demographicStats.gender.female + 1;
                                        demographicStats.age[ageCategory].female = demographicStats.age[ageCategory].female + 1;
                                        break;
                                    case "Miss":
                                        demographicStats.gender.female = demographicStats.gender.female + 1;
                                        demographicStats.age[ageCategory].female = demographicStats.age[ageCategory].female + 1;
                                        break;
                                    case "Sir":
                                        demographicStats.gender.male = demographicStats.gender.male + 1;
                                        demographicStats.age[ageCategory].male = demographicStats.age[ageCategory].male + 1;
                                        break;

                                }

                            }
                            catch (Err) { }
                        }
                        catch (err) { }

                    }
                }

            });

            //console.log(demographicStats);

            dispath(saveDemographicStats(demographicStats));
            dispath(saveDemographicStats(null));
            dispath(saveFetchStatus(null));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
            dispath(saveFetchStatus(null));
        });

}

export const getStatusList = (data) => dispath => {
    dispath(saveFetchStatus("Fetching"));

    axios.get(apidetails.API_URL + 'status/', qs.stringify(data), config)
        .then(res => {
            var statusList = res.data.statusList;
            dispath(saveStatusList(statusList));
            dispath(saveFetchStatus(null));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
            dispath(saveFetchStatus(null));
        });
}

export const getLeadVolumeSummary = (data) => dispath => {
    dispath(saveFetchStatus("Fetching"));

    if (data === undefined) {
        data = {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        }
    }
    else {
        data.fromDate = moment(data.fromDate).format('YYYY-MM-DD');
        data.toDate = moment(data.toDate).format('YYYY-MM-DD');
    }

    axios.post(apidetails.API_URL + 'salesInformation/leadSourceVolumeSummary', qs.stringify(data), config)
        .then(res => {

            
            var finalLeadVolumeSummary = res.data.result;
            

            //console.log("getLeadVolumeSummaryLog",finalLeadVolumeSummary)

            !isEmpty(finalLeadVolumeSummary) && dispath(saveLeadVolumeSummary(finalLeadVolumeSummary));

            dispath(saveLeadVolumeSummary(null))


            

        })
        .catch(err => {
            console.log("getLeadVolumeSummaryLog",err)
            dispath(error(err.message || 'ERROR'));
        });


}