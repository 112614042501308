import React from "react";
import {Route, Switch} from "react-router-dom";
//import ScriptTool from "./ScriptTool";
import OriginNMIMIRNTool from "./OriginNMIMIRNTool";
import BPIDGeneratorTool from "./BPIDGeneratorTool";
import AssignCompareBroadbandLeads from "./AssignCompareBroadbandLeads";
import AddToDNCRegister from "./AddToDNCRegister";

const Tools = ({match}) => (
  <Switch>
    <Route path={`${match.url}/originNMIMIRNTool`} component={OriginNMIMIRNTool}/>
    {/* <Route path={`${match.url}/bpidGeneratorTool`} component={BPIDGeneratorTool}/> */}
    <Route path={`${match.url}/addToDNCRegister`} component={AddToDNCRegister}/>
    <Route path={`${match.url}/assignCompareBroadbandLeads`} component={AssignCompareBroadbandLeads}/>
  </Switch>
);

export default Tools;
