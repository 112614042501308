const initialState = {
    leadDetails: null,
    leadAssignmentToAgentMessage: null,
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SINGLE_LEAD_DETAILS':
            return {
                ...state,
                leadDetails: action.leadDetails
            };
        case 'LEAD_ASSIGNED_TO_AGENT_MESSAGE':
            return {
                ...state,
                leadAssignmentToAgentMessage: action.leadAssignmentToAgentMessage
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}