import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveSalesToProcess = salesToProcess => ({
  type: 'SAVE_SALES_TO_PROCESS',
  salesToProcess,
});

export const saveOrderUpdates = orderUpdates => ({
  type: 'SAVE_ORDER_UPDATES',
  orderUpdates,
});

export const saveProcessSaleLogs = processSaleLogs => ({
  type: 'SAVE_PROCESS_LOGS',
  processSaleLogs,
});

export const saveProcessSaleBatchDetails = processSaleBatchDetails => ({
  type: 'SAVE_PROCESS_SALE_BATCH_DETAILS',
  processSaleBatchDetails,
});

export const saveRetailerCallbackLogs = retailerCallbackLogs => ({
  type: 'SAVE_RETAILER_CALLBACK_LOGS',
  retailerCallbackLogs,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
  type: 'SAVE_RECORD_UPDATE_STATUS',
  recordUpdateStatus,
});

export const error = error => ({
  type: 'ERROR',
  error,
});

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const getSalesToProcess = (data) => dispath => {

  dispath(saveSalesToProcess(null));
  if (data !== undefined) {
    axios.post(apidetails.API_URL + 'processSales/salesToProcess', qs.stringify(data), config)
      .then(res => {
        if (res.data.recordCount != 0)
        {
          dispath(saveSalesToProcess(res.data.saleRecords));
          dispath(saveSalesToProcess(null));
        }
        else
        {
          dispath(saveSalesToProcess([]));
          dispath(saveSalesToProcess(null));
        } 
      })
      .catch(err => {
        console.log(err);
        //dispath(error(err.response.data.message || 'ERROR'));
      });
  }
  else {
    dispath(error("No Payload Found" || 'ERROR'));
  }
}

export const clearSales = (data) => dispath => {

  dispath(saveSalesToProcess(null));
}

export const processSale = (data) => dispath => {

  dispath(saveRecordUpdateStatus(null));
  setTimeout(function () {
  axios.post(apidetails.API_URL + 'processSales/processSale', qs.stringify(data), config)
    .then(res => {
      dispath(saveRecordUpdateStatus(res.data));
      dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
      console.log(err);
      //dispath(error(err.response.data.message || 'ERROR'));
    });
  }, 2000);
}

export const getOrderUpdates = (data) => dispath => {

  dispath(saveOrderUpdates(null));
  setTimeout(function () {
    axios.post(apidetails.API_URL + 'processSales/getOrderUpdates', qs.stringify(data), config)
      .then(res => {
        dispath(saveOrderUpdates(res.data.results));
        dispath(saveOrderUpdates(null));
      })
      .catch(err => {
        console.log(err);
        //dispath(error(err.response.data.message || 'ERROR'));
      });
  }, 45000);


}

export const getProcessSaleLogs = (data) => dispath => {

  dispath(saveProcessSaleLogs(null));
  //dispath(error(null));

  axios.post(apidetails.API_URL + 'processSales/getProcessSaleLogs', qs.stringify(data), config)
  .then(res => {
    dispath(saveProcessSaleLogs(res.data.results));
  })
  .catch(err => {
    console.log(err.response.data.message);
    dispath(error(err.response.data.message || 'ERROR'));
    //dispath(error(null));
  });

}

export const getRetailerSaleCallbackLogs = (data) => dispath => {

  axios.post(apidetails.API_URL + 'processSales/getRetailerSaleCallbackLogs', qs.stringify(data), config)
  .then(res => {
    dispath(saveRetailerCallbackLogs(res.data.results));
  })
  .catch(err => {
    console.log(err);
    //dispath(error(err.response.data.message || 'ERROR'));
  });
  
}

export const getProcessSaleBatchDetails = (data) => dispath => {

  axios.post(apidetails.API_URL + 'processSales/getProcessSaleBatchDetails', qs.stringify(data), config)
  .then(res => {
    dispath(saveProcessSaleBatchDetails(res.data.results));
  })
  .catch(err => {
    console.log(err);
    //dispath(error(err.response.data.message || 'ERROR'));
  });
  
}