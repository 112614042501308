import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/products";
import { getRetailerList } from "appRedux/actions/admin/retailers";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;

const moduleName = "Products"
const moduleNameUPPER = "PRODUCTS"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class ManageTariffs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                "id": false,
                "fuel": false,
                "state": false,
                "retailer": false,
                "res_sme": false,
                "product_name": false,
                "field_value": false,
                "created": false
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.state.localeCompare(b.state) },
            },
            {
                title: 'Retailer',
                dataIndex: 'retailer',
                key: 'retailer',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
            },
            {
                title: 'Fuel',
                dataIndex: 'fuel',
                key: 'fuel',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.fuel.localeCompare(b.fuel) },
            },
            {
                title: 'RES/SME',
                dataIndex: 'res_sme',
                key: 'res_sme',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
            },

            {
                title: 'Product Name',
                dataIndex: 'product_name',
                key: 'product_name',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.product_name.localeCompare(b.product_name) },
            },
            {
                title: 'Field Value',
                dataIndex: 'field_value',
                key: 'field_value',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.field_value.localeCompare(b.field_value) },
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                "id": true,
                "fuel": true,
                "state": true,
                "retailer": true,
                "res_sme": true,
                "product_name": true,
                "field_value": true,
                "created": true
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                "id": false,
                "fuel": false,
                "state": false,
                "retailer": false,
                "res_sme": false,
                "product_name": false,
                "field_value": false,
                "created": false
            }
        })
    };

    duplicateRecord(record) {

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ created: true })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtResSmeSearch;
        delete values.txtRetailerSearch;
        delete values.txtFuelSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values)
            delete values.created;

            values.id = this.state.singleRecordDetails.id;

            values = this.removeSearchValuesFromPayload(values);

            this.setState({
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateSingleRecord(values);
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtRetailerSearch != "-1" && { retailer: values.txtRetailerSearch }),
                ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
                ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
                ...(values.txtFuelSearch != "-1" && { fuel: values.txtFuelSearch }),

            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.productRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.productRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="Victoria">Victoria</Option>
                                                            <Option value="New South Wales">New South Wales</Option>
                                                            <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                            <Option value="Queensland">Queensland</Option>
                                                            <Option value="South Australia">South Australia</Option>
                                                            <Option value="Western Australia">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="RES/SME" >
                                                    {getFieldDecorator('txtResSmeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('txtRetailerSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Retailer"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Retailers</Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerFriendlyName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Fuel" >
                                                    {getFieldDecorator('txtFuelSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="Elec">Electricity</Option>
                                                            <Option value="Gas">Gas</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            <Row>
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="State" >
                                                            {getFieldDecorator('state', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.state : undefined,
                                                                rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.state}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select State"
                                                                >
                                                                    <Option value="Victoria">Victoria</Option>
                                                                    <Option value="New South Wales">New South Wales</Option>
                                                                    <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                                    <Option value="Queensland">Queensland</Option>
                                                                    <Option value="South Australia">South Australia</Option>
                                                                    <Option value="Western Australia">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="RES/SME" >
                                                            {getFieldDecorator('res_sme', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.res_sme : undefined,
                                                                rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.res_sme}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="RES">RES</Option>
                                                                    <Option key="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Fuel" >
                                                            {getFieldDecorator('fuel', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.fuel : undefined,
                                                                rules: [{ required: true, message: 'Please select Fuel Type!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.fuel}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Elec">ELEC</Option>
                                                                    <Option key="Gas">GAS</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Retailer" >
                                                            {getFieldDecorator('retailer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.retailer : undefined,
                                                                rules: [{ required: true, message: 'Please select a Retailer!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.retailer}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Retailer"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                        return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerFriendlyName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Product Name" >
                                                            {getFieldDecorator('product_name', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_name : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.product_name} style={{ width: '100%' }} placeholder="Enter Product Name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Field Value" >
                                                            {getFieldDecorator('field_value', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.field_value : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.field_value} style={{ width: '100%' }} placeholder="Enter Field Value" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe} style={{ marginLeft: "10px" }}>
                                <Button type="danger">
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,

    productRecords: state.product.resultList,
    recordUpdateStatus: state.product.recordUpdateStatus,
    error: state.product.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManageTariffs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);