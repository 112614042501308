import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";

import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

import setAuthToken from 'util/setAuthToken';
import isEmpty from "util/isEmpty";
import {setWithExpiry, removeKey} from '../../util/customSessionManager';

const config = {
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
  }
}

let accessLevelDetails = null;
let modulesAccess = null;

const createUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await  auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await  auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await  auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const initializeUser = async (signUpUser) => {
  
  return new Promise(resolve => {
  
    let userData = {
      email: signUpUser.additionalUserInfo.profile.email,
    }

    axios.post(apidetails.API_URL + 'users/search', qs.stringify(userData), config)
    .then(res => {
        if(isEmpty(res.data.users)) 
        {
          userData = {
            name: signUpUser.additionalUserInfo.profile.name,
            email: signUpUser.additionalUserInfo.profile.email,
          }

          axios.post(apidetails.API_URL + 'users/create', qs.stringify(userData), config)
          .then(createRes => {
            resolve(true)
          })
          .catch(createErr => {
          });
        }
        else
        {

          if(res.data.users[0].isActive == 0)
          {            
            resolve(false)
          }

          let accessLevelInfoToSearch = {
            id: res.data.users[0].accessLevel,
          }

          axios.post(apidetails.API_URL + 'accessLevels/search', qs.stringify(accessLevelInfoToSearch), config)
          .then(accessLevelRes => {
            accessLevelDetails = accessLevelRes.data.accessLevel;
            //localStorage.setItem('accessLevelDetails', JSON.stringify(accessLevelDetails));
            setWithExpiry('accessLevelDetails',JSON.stringify(accessLevelDetails))

            let moduleAccessInfoToSearch = {
              accessLevel: accessLevelDetails[0].id,
            }

            axios.post(apidetails.API_URL + 'moduleAccess/search', qs.stringify(moduleAccessInfoToSearch), config)
            .then(moduleAccessInfoRes => {

              modulesAccess = moduleAccessInfoRes.data.modules;
              //localStorage.setItem('modulesAccess', JSON.stringify(modulesAccess));
              setWithExpiry('modulesAccess',JSON.stringify(modulesAccess))

              userData = {
                id:res.data.users[0].id,
                name: signUpUser.additionalUserInfo.profile.name,
                email: signUpUser.additionalUserInfo.profile.email,
              }

              axios.post(apidetails.API_URL + 'users/updateLogin', qs.stringify(userData), config)
              .then(updateLoginRes => {
                resolve(true)
              })
              .catch(updateLoginErr => {
              });

            })
            .catch(moduleAccessInfoErr => {
            });

          })
          .catch(accessLevelErr => {
          });
        }
    })
    .catch(err => {
    });
  });
};

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      setWithExpiry('user_id',signUpUser.user.uid)

      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);

    var emailDomain = signUpUser.additionalUserInfo.profile.hd;

    if(emailDomain === 'electricitywizard.com.au' || emailDomain === 'seekdeals.com.au')
    {  
      setAuthToken(signUpUser.user.za);

      let data = yield call(initializeUser, signUpUser);

      if(data)
      {
        //localStorage.setItem('user_id', signUpUser.user.uid);
        setWithExpiry('user_id',signUpUser.user.uid)

        //localStorage.setItem('user_details', JSON.stringify(signUpUser.additionalUserInfo.profile));
        setWithExpiry('user_details',JSON.stringify(signUpUser.additionalUserInfo.profile))

        //localStorage.setItem('tokenID', signUpUser.user.qa);
        console.log(signUpUser.user)
        setWithExpiry('tokenID',signUpUser.user.za)
        

        yield put(userGoogleSignInSuccess(signUpUser.user.uid, signUpUser.additionalUserInfo.profile, signUpUser.user.qa, accessLevelDetails, modulesAccess));
      }
      else
      {
        yield put(showAuthMessage("You are not authorised to Login"));
      }
    }
    else
    {
      yield put(showAuthMessage("You are not authorised to Login"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}



function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      setWithExpiry('user_id',signUpUser.user.uid)

      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      setWithExpiry('user_id',signUpUser.user.uid)

      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      setWithExpiry('user_id',signUpUser.user.uid)

      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      //localStorage.setItem('user_id', signInUser.user.uid);
      setWithExpiry('user_id',signInUser.user.uid)

      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      // localStorage.removeItem('user_id');
      // localStorage.removeItem('user_details');
      // localStorage.removeItem('tokenID');
      // localStorage.removeItem('accessLevelDetails');
      // localStorage.removeItem('modulesAccess');

      removeKey('user_id');
      removeKey('user_details');
      removeKey('tokenID');
      removeKey('accessLevelDetails');
      removeKey('modulesAccess');

      setAuthToken(false);
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser)]);
}
