import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, Tooltip, Icon,Tag } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getMailingList, updateMailingList } from "appRedux/actions/admin/manageMailingList";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }


    if (this.props.inputType === 'statusSelection') {

      return <Switch disabled={false} defaultChecked={record[dataIndex] == 1 ? true : false} onChange={onChange} />;

    }
    return <Input style={{ minWidth: '150px' }} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "fixupReports" | dataIndex == "financeReports" | dataIndex == "salesReports") {
        return record[dataIndex] == 1 ? true : false;
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display:'block',  }}>
            {getFieldDecorator(dataIndex, {
              align: 'center',
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class DistributorTranslation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      distributorRecordIdToDelete: null,
      showAddNewDistributorTranslationPanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}

                    >
                      <i className="icon icon-check-circle-o" />
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
              <span>
                <center>
                  <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                    <i className="icon icon-edit" />
                  </a>
                </center>
              </span>
            );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.updatedDate - b.updatedDate,
        render: (text, record) => {
          return <Tag color={"volcano"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 220,
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 220,
        sorter: (a, b) => { return a.email.localeCompare(b.email) },
      },
      {
        title: 'Dev Mode',
        dataIndex: 'devMode',
        key: 'devMode',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => a.devMode - b.devMode,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Daily Sales Reports',
        dataIndex: 'dailySalesReports',
        key: 'dailySalesReports',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => a.dailySalesReports - b.dailySalesReports,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Origin LPG Lead Report',
        dataIndex: 'originLPGLeadReport',
        key: 'originLPGLeadReport',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => a.originLPGLeadReport - b.originLPGLeadReport,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Hourly Call Stats Report',
        dataIndex: 'hourlyCallStatsReport',
        key: 'hourlyCallStatsReport',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => a.hourlyCallStatsReport - b.hourlyCallStatsReport,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id: item.id,
          devMode: row.devMode,
          dailySalesReports: row.dailySalesReports ? 1 : 0,
          originLPGLeadReport: row.originLPGLeadReport ? 1 : 0,
          hourlyCallStatsReport: row.hourlyCallStatsReport ? 1 : 0,
        }

        this.setState({ loading: true });
        this.props.updateMailingList(valuesToUpdate);


        this.setState({
          editingKey: '',
          recordsEdited: true
        });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  onConfirm = () => {
    this.setState({
      showSuccess: false,
    });
    this.handleReset();
  };

  componentDidMount() {

    this.props.getMailingList();
  }

  // handleSearch = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {

  //     const filtersForSearch = {
  //       name: values.txtStatusName,
  //     }
  //     this.setState({ isSearching: true });
  //     this.props.getMailingList(filtersForSearch);
  //   });
  // };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getMailingList();
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'devMode': return "statusSelection";
      case 'dailySalesReports': return "statusSelection";
      case 'originLPGLeadReport': return "statusSelection";
      case 'hourlyCallStatsReport': return "statusSelection";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.mailingList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.mailingList.map((mailingUser, key) => {
        let record = {
          key: count.toString(),
          id: mailingUser.id,
          updatedDate: moment(mailingUser.updatedDate).format('YYYY-MM-DD h:mm:ss A'),
          name: !isEmpty(mailingUser.name) ? mailingUser.name : "-",
          email: !isEmpty(mailingUser.email) ? mailingUser.email : "-",
          devMode: mailingUser.devMode,
          dailySalesReports: mailingUser.dailySalesReports,
          originLPGLeadReport: mailingUser.originLPGLeadReport,
          hourlyCallStatsReport: mailingUser.hourlyCallStatsReport,
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getMailingList();
        this.setState({
          isAdding: false,
        });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Mailing Lists" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Users">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Status" >
                          {getFieldDecorator('txtStatusName', {
                            rules: [{ required: false, message: 'Please input Status to Search!' }],
                          })(
                            <Input style={{ width: '100%' }} placeholder="Enter Status Name" />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}
          <Col span={24}>
            <Card className="gx-card" title="Mailing List Details">
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mailingList: state.manageMailingList.mailingList,
  recordUpdateStatus: state.manageMailingList.recordUpdateStatus,
});

const mapDispatchToProps = dispath => ({
  getMailingList: (data) => dispath(getMailingList(data)),
  updateMailingList: (data) => dispath(updateMailingList(data)),
});

const WrappedAdvancedSearch = Form.create()(DistributorTranslation);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);