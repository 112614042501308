import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"

export const saveSingleLeadDetails = leadDetails => ({
  type: 'SAVE_SINGLE_LEAD_DETAILS',
  leadDetails,
});

export const saveLeadAssignmentMessage = leadAssignmentToAgentMessage => ({
  type: 'LEAD_ASSIGNED_TO_AGENT_MESSAGE',
  leadAssignmentToAgentMessage,
});

export const error = error => ({
  type: 'ERROR',
  error,
});

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const clearAll = () => dispath => {

  dispath(saveSingleLeadDetails(null));
  dispath(saveLeadAssignmentMessage(null));
  dispath(error(null));

}


export const getSingleLeadDetails = (leadId) => dispath => {

  dispath(saveSingleLeadDetails(null));
  axios.get(apidetails.API_URL + `leads/${leadId}`)
    .then(res => {
      dispath(saveSingleLeadDetails(res.data.result));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}

export const assignLeadToAgent = (data) => dispath => {
 
  dispath(saveLeadAssignmentMessage(null));
  axios.post(apidetails.API_URL + `leads/assignToAgent`, qs.stringify(data), config)
    .then(res => {
      dispath(saveLeadAssignmentMessage(res.data.message));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}