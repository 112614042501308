import React from "react";
import {Route, Switch} from "react-router-dom";
import Plans from "./Plans";
import GasRates from "./GasRates";
import ElecRates from "./ElecRates";
import DMOVDO from "./DMOVDO";
import Tariffs from "./Tariffs";
import Products from "./Products";
import PDFs from "./PDFs";
import Consumptions from "./Consumptions";
import ElecBPIDs from "./ElecBPIDs";
import GasBPIDs from "./GasBPIDs";
import DNCRegister from "./DNCRegister";
import RateToolUpdates from "./RateToolUpdates"

const Manage = ({match}) => (
  <Switch>
    <Route path={`${match.url}/plans`} component={Plans}/>
    <Route path={`${match.url}/gasRates`} component={GasRates}/>
    <Route path={`${match.url}/elecRates`} component={ElecRates}/>
    <Route path={`${match.url}/dmoVdo`} component={DMOVDO}/>
    <Route path={`${match.url}/tariffs`} component={Tariffs}/>
    <Route path={`${match.url}/products`} component={Products}/>
    <Route path={`${match.url}/PDFs`} component={PDFs}/>
    <Route path={`${match.url}/consumptions`} component={Consumptions}/>
    <Route path={`${match.url}/elecBPIDs`} component={ElecBPIDs}/>
    <Route path={`${match.url}/gasBPIDs`} component={GasBPIDs}/>
    <Route path={`${match.url}/dncRegister`} component={DNCRegister}/>
    <Route path={`${match.url}/rateToolUpdates`} component={RateToolUpdates}/>
  </Switch>
);

export default Manage;
