import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, DatePicker } from "antd";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getSalesDataPerHour, getLeadVolumeSummary } from "appRedux/actions/sales/salesInformation";

import isEmpty from "util/isEmpty";

import randomColor from "randomcolor";

import Widget from "components/Widget/index";

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

var momentTimezone = require('moment-timezone');

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class HourlySalesStats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      threeWeekDaySaleStats: [],
      fiveWorkingDaysaleStats: [],
      salesPerHourData: [],
      salesSummaryData: {
        totalSales: 0,
        totalElec: 0,
        totalGas: 0,
      },
      isSearching: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {

    this.setState({
      isFetchingData: true,
    })

    this.props.getLeadVolumeSummary();

    //this.props.getSalesDataPerHour();
  }

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })
  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate, dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {

      if (now.day() != 0) {
        dates.push(now.format('YYYY-MM-DD'));
      }

      //dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
      }
      this.setState({
        isFetchingData: true,
        isSearching: true,
        salesPerHourData: [],
        threeWeekDaySaleStats: [],
        fiveWorkingDaysaleStats: [],
        fiveWorkingDayLeadStats: [],
        leadSourceListWithStats: [],
        salesSummaryData: {
          totalSales: 0,
          totalElec: 0,
          totalGas: 0,
        },
      });
      this.props.getSalesDataPerHour(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  componentWillReceiveProps(nextProps) {

    

    if (!isEmpty(nextProps.leadVolumeSummary)) {
        console.log("getLeadVolumeSummaryLog",nextProps.leadVolumeSummary)
        this.setState({
            leadSourceListWithStats: nextProps.leadVolumeSummary.leadSourceList,
          isFetchingData: false,
          isSearching: false,
        });
      }

    if (!isEmpty(nextProps.fiveWorkingDaysaleStats)) {
      this.setState({
        fiveWorkingDaysaleStats: nextProps.fiveWorkingDaysaleStats,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.salesPerHourList)) {
      this.setState({
        salesPerHourData: nextProps.salesPerHourList,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.threeWeekDaySaleStats)) {
      this.setState({
        threeWeekDaySaleStats: nextProps.threeWeekDaySaleStats,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.salesSummaryData)) {
      this.setState({
        salesSummaryData: nextProps.salesSummaryData,
      });
    }
  }

  render() {


    const { getFieldDecorator } = this.props.form;

    var fiveDayWorkingDayList = []

    if(!isEmpty(this.state.startDate))
    {
      fiveDayWorkingDayList = this.enumerateDaysBetweenDates(momentTimezone(this.state.startDate,"YYYY/MM/DD").add(-5, "days"), momentTimezone(this.state.startDate,"YYYY/MM/DD"));
    }
    else
    {
      fiveDayWorkingDayList = this.enumerateDaysBetweenDates(momentTimezone().add(-5, "days"), momentTimezone());
    }

    

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Lead Volume Summary" />
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Sales Information">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={18} md={18} sm={18} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sales Date Range">
                          {getFieldDecorator('drpDateRange', {
                            rules: [{ required: false, message: 'Please select a Date Range' }],
                          })(
                            <RangePicker className="gx-w-100"
                              ranges={{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }}
                              format="YYYY/MM/DD"
                              defaultValue={[moment(), moment()]}
                              onChange={this.handleDateRangeChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>         
          {/* <Col span={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Historical Sales per Hour Trail">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={this.state.threeWeekDaySaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="thisWeek" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="lastWeek" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="weekBeforeLast" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSales" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Historical Total Sales Trail">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={this.state.threeWeekDaySaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="thisWeekCummulative" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="lastWeekCummulative" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="weekBeforeLastCummulative" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSalesCummulative" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col> */}
          {this.state.isFetchingData && <Col md={24}>
              <Widget styleName="gx-card-full gx-text-center gx-pt-4 gx-pb-3 gx-px-3">
                <div className="gx-separator gx-bg-primary"/>
                <h2 className="gx-mb-4 gx-text-primary">Loading Data... Please allow upto 2-5 minutes to load data... </h2>
                <Spin spinning={this.state.isFetchingData}></Spin>
              </Widget>
            
          </Col>}
          {this.state.isFetchingData == false && !isEmpty(this.state.leadSourceListWithStats) && this.state.leadSourceListWithStats.map((leadSource, key) => {
            return <Row>
                    <Col md={24}>
                      <Widget styleName="gx-blue-cyan-gradient gx-text-white gx-card-1367-p">
                        <h2 className="gx-text-white">{leadSource.name}</h2>
                      </Widget>
                    </Col>
                    <Col md={12}>
                      <Card className="gx-card" title={`Lead Volume Summary - Last 5 Working Days`}>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={leadSource.lastFiveWorkingDaysLeadStats}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="section" interval={"preserveStartEnd"} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey={`${fiveDayWorkingDayList[4]}`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey={`${fiveDayWorkingDayList[3]}`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                            <Line type="monotone" dataKey={`${fiveDayWorkingDayList[2]}`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey={`${fiveDayWorkingDayList[1]}`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey={`${fiveDayWorkingDayList[0]}`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="gx-card" title={`Lead Volume Summary - Last 5 Working Days (Cummulative)`}>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={leadSource.lastFiveWorkingDaysLeadStats}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="section" interval={"preserveStartEnd"} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey={`Day4Total`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey={`Day3Total`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                            <Line type="monotone" dataKey={`Day2Total`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey={`Day1Total`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey={`Day0Total`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="gx-card" title={`Lead Volume Summary - Same Workday Previous Weeks`}>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={leadSource.fourWeekStats}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="section" interval={"preserveStartEnd"} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="thisWeek" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="lastWeek" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                            <Line type="monotone" dataKey="weekBeforeLast" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSales" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card className="gx-card" title={`Lead Volume Summary - Same Workday Previous Weeks (Cummulative)`}>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={leadSource.fourWeekStats}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="section" interval={"preserveStartEnd"} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="thisWeekCummulative" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="lastWeekCummulative" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                            <Line type="monotone" dataKey="weekBeforeLastCummulative" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                            <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSalesCummulative" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                    </Col>
                    </Row>
          })}
          
          {/* <Col md={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Total Sales Stats Last 5 Working Days">
                <ResponsiveContainer width="100%" height={500}>
                  <LineChart data={this.state.fiveWorkingDaysaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={`Day4Total`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey={`Day3Total`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey={`Day2Total`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`Day1Total`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`Day0Total`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col> */}
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fiveWorkingDaysaleStats: state.salesinformation.fiveWorkingDaysaleStats,
  threeWeekDaySaleStats: state.salesinformation.threeWeekDaySaleStats,
  salesPerHourList: state.salesinformation.salesPerHourList,
  salesSummaryData: state.salesinformation.salesSummaryData,
  leadVolumeSummary: state.salesinformation.leadVolumeSummary,
});

const mapDispatchToProps = dispath => ({
  getSalesDataPerHour: (data) => dispath(getSalesDataPerHour(data)),
  getLeadVolumeSummary: (data) => dispath(getLeadVolumeSummary(data)),
});

const WrappedAdvancedSearch = Form.create()(HourlySalesStats);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);